<template>
    <div>
        <Appheader v-if="user!=null" :idminer="user.idminer" :uname="user.name" :tnotif="tnotif" :samount="samount" :botid="user.botid" />
        <!-- App Capsule -->
        <div id="appCapsule" class="full-height">

            <div class="section mt-2 mb-2">
                <div class="listed-detail text-center mt-3">
                    <img v-if="user!=null && user.package_name=='XONE'" src="/soft-1.png" class="img-fluid">
                    <img v-if="user!=null && user.package_name=='XTWO'" src="/soft-2.png" class="img-fluid">
                    <img v-if="user!=null && user.package_name=='XTHREE'" src="/soft-3.png" class="img-fluid">
                    <!-- <div class="icon-wrapper">
                        <div class="iconbox">
                            <ion-icon name="apps-outline"></ion-icon>
                        </div>
                    </div> -->
                    <h3 class="text-center mt-2">Package Info</h3>
                </div>

                <ul class="listview flush transparent simple-listview no-space mt-3 ps-1 pe-1">
                    <li>
                        <strong>Package Name</strong>
                        <span v-if="user!=null">{{ user.package_name }}</span>
                    </li>
                    <li>
                        <strong>Status</strong>
                        <span class="text-success" v-if="user!=null">{{ user.package_status }}</span>
                    </li>
                    <li>
                        <strong>Limit</strong>
                        <span v-if="user!=null">{{ user.package_limit }}</span>
                    </li>
                    <li>
                        <strong>Valid Until</strong>
                        <span v-if="user!=null">{{ user.package_until }}</span>
                    </li>
                </ul>


            </div>

        </div>
        <!-- * App Capsule -->

        <Appbottom />
    </div>
</template>

<script>
import axios from 'axios'
import Appheader from '../components/Appheader'
import Appbottom from '../components/Appbottom'

export default {
    name: 'Package',
    // props:['user'],
    components: {
        Appheader,
        Appbottom
    },
    data() {
        return {
            user: null,
            token: null,
            tnotif: 0,
            samount: 0
        }
    },
    async mounted() {
        this.$router.push('/exporttosmartcontract')
        // // console.log('mounted')
        // if(!localStorage.getItem('token')) {
        // this.$router.push('/login');
        // }

        // this.token = localStorage.getItem('token');

        // if(this.user==null) {
        //     await this.getUser();
        // }
    },
    methods: {        
        async getUser() {
            if(localStorage.getItem('token')) {
                // console.log('get user')
                try {
                    if (localStorage.getItem('sescode') == null) {
                        this.$router.push('/pinverification');
                    } else {
                        let response = await axios.get('user/' + process.env.VUE_APP_CODE + '/' + localStorage.getItem('sescode'), {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token')
                            }
                        });

                        let res = response.data;
                        // console.log(res)
                        if (res.user) {
                            // if pin not set
                            if (res.user.pin_created == null) {
                                this.$router.push('/pin')
                            }
                            // if pin session not active
                            if (res.sesstatus != 'active') {
                                localStorage.removeItem('sescode');
                                this.$router.push('/pinverification');
                            }
                            this.user = res.user;
                            this.tnotif = res.totnotif;
                            this.samount = res.samount;
                        } else {
                            localStorage.removeItem('token');
                            this.$router.push('/login')
                        }
                    }
                    
                } catch (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('token');
                        this.$router.push('/login')
                    }
                }
            } else {
                this.$router.push('/login')
            }    
        },
    },
}
</script>