import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './axios'
import VueConfirmDialog from 'vue-confirm-dialog'
// import vSelect from 'vue-select'
import VueQrcode from '@chenfengyuan/vue-qrcode';

import VueClipboard from 'vue-clipboard2'
import VueApexCharts from 'vue-apexcharts'

import VueQrcodeReader from "vue-qrcode-reader";

import moment from 'moment-timezone'

import '../node_modules/nprogress/nprogress.css'

const { create, all } = require('mathjs')
const config = {
    number:'BigNumber',
    precision:20
}
const math = create(all,config)

Vue.prototype.$math = math;

Vue.config.productionTip = false

Vue.config.ignoredElements = [/^ion-/]
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
// Vue.component('v-select', vSelect)
Vue.component(VueQrcode.name, VueQrcode);

Vue.use(VueQrcodeReader);

// moment.tz.setDefault('Asia/Jakarta')
Vue.prototype.$moment = moment

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')