<template>
    <div>
        <div class="loader" v-if="loading"></div>
        <div class="appHeader bg-primary text-light">
            <div class="pageTitle">
                <!-- <img src="/assets/img/logo.png" alt="logo" class="logo"> -->
                DCT MINER
            </div>
        </div>

        <!-- App Capsule -->
        <div id="appCapsule">

            <!-- Wallet Card -->
            <div class="section wallet-card-section pt-1">
                <div class="wallet-card">
                    <div class="listed-detail text-center mt-3" v-if="user.staker!=null">
                        <h2 class="mt-5">Do Migration</h2>
                        <p v-if="errors.length">
                        <ul>
                            <li class="text-danger">{{ errors }}</li>
                        </ul>
                        </p>
                        <div v-if="user && user.showkeystatus == 'used' && user.exportstatus == 'exported'">
                            <h4 class="text-danger">WARNING! The migration has beed done<br />
                                Please return to the main page to see your export status.<br />
                                <a href="https://staking.degreecrypto.com/#/exporttosmartcontract" class="btn btn-link">https://staking.degreecrypto.com</a>
                            </h4>
                        </div>
                        <div v-if="user && user.showkeystatus == 'used' && user.exportstatus == 'queue'">
                            <h4 class="text-danger">WARNING! The migration is on progress</h4>
                        </div>
                        <div v-if="user && user.showkeystatus == '-'">
                            <h4 class="text-danger">Invalid request!</h4>
                        </div>
                        <div v-if="user && user.showkeystatus == 'generated'">
                            <p>Please insert your password to process migration!</p>
                            <form @submit.prevent="doMigration()">
                                <div class="form-group">
                                    <label for="">Old Miner Address</label>
                                    <input type="text" v-model="user.addr" class="form-control text-center" readonly>
                                    <p class="text-danger">Note : make sure you have 200 TRX at this address</p>
                                </div>
                                <div class="form-group">
                                    <label for="">Staker Address</label>
                                    <input type="text" v-model="user.staker" class="form-control text-center" readonly>
                                    <p class="text-danger">Note : this address will be used as a staker in the new miner application</p>
                                </div>
                                <div class="form-group">
                                    <label for="">ID Miner</label>
                                    <input type="text" v-model="idminer" class="form-control text-center" id="idminer" placeholder="">
                                </div>
                                <div class="form-group">
                                    <label for="">Miner Password</label>
                                    <input type="password" class="form-control text-center" name="password" id="password" v-model="password" placeholder="">
                                </div>
                                <button type="submit" name="showkey" id="showkey" class="btn btn-primary btn-block mt-1">Migration Now</button>
                            </form>
                        </div>
                    </div>

                    <div class="listed-detail text-center mt-3" v-if="user.staker == null && user.exportstatus=='exported'">
                        <h2 class="mt-5">Show Address Key</h2>
                        <div v-if="user && user.showkeystatus == 'used' && user.exportstatus == 'exported'">
                            <h4 class="text-danger">WARNING! The migration has beed done<br />
                                Please return to the main page to see your export status.<br />
                                <a href="https://staking.degreecrypto.com/#/exporttosmartcontract" class="btn btn-link">https://staking.degreecrypto.com</a>
                            </h4>
                        </div>
                        <div v-if="user && user.showkeystatus == 'generated'">
                            <p>Please insert your password to show address key!</p>
                            <form @submit.prevent="doPrivateKey()">
                                <div class="form-group">
                                    <label for="">ID Miner</label>
                                    <input type="text" v-model="idminerx" class="form-control text-center" id="idminer" placeholder="">
                                </div>
                                <div class="form-group">
                                    <label for="">Miner Password</label>
                                    <input type="passwordx" class="form-control text-center" name="password" id="password" v-model="passwordx" placeholder="">
                                </div>
                                <button type="submit" name="showkey" id="showkey" class="btn btn-primary btn-block mt-1">Show Private Key</button>
                            </form>
                        </div>
                        <div v-if="addrkey != null">
                            <p class="text-responsive" v-if="user">{{ user.addr }}</p>
                            <qrcode v-if="user" :value=addrkey :options="{ width: 200 }"></qrcode>
                        
                            <textarea name="addrkey" class="form-control mb-3" readonly v-model="addrkey"></textarea>

                            <button v-if="user" type="button" class="btn btn-primary btn-block btn-lg mb-3"
                                v-clipboard:copy="addrkey"
                                v-clipboard:success="onCopy"
                                v-clipboard:error="onError"
                                data-bs-dismiss="modal">
                                Copy Private Key</button>
                                <h4 class="text-danger">Note: the address key will only be displayed ONE TIME. Make sure you copy the key shown. For the security of your assets, KEEP YOUR KEYS SECRET</h4>
                        </div>
                    </div>
                    <!-- * Wallet Footer -->
                </div>
            </div>
            <!-- Wallet Card -->

            <!-- Extra Header -->
            <div class="extraHeader pe-0 ps-0">
                
            </div>
            <!-- * Extra Header -->

        </div>
        <!-- * App Capsule -->        

        <Toast ref="toastx" />

        <div class="appBottomMenu">
                
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import Toast from '../components/Toast'

export default {
    name: 'Showaddrkey',
    components: {
        Toast
    },
    // props:['user'],
    data() {
        return {
            errors: '',
            user: null,
            token: null,
            contractDec: 10 ** 18,
            idminer: null,
            password: null,
            idminerx: null,
            passwordx: null,
            addrkey: null,
            loading: true,
            keycode: null
        }
    },
    async mounted() {
        this.loading = false;
        // remove background
        // console.log('mounted')
        document.querySelector('body').classList.remove('modal-open');
        var elements = document.getElementsByClassName('modal-backdrop');
        // console.log(elements.length)
        if (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
        
        this.keycode = this.$route.params.keycode;
        console.log(this.keycode)

        // // console.log('mounted')
        // if(!localStorage.getItem('token')) {
        // this.$router.push('/login');
        // }

        // this.token = localStorage.getItem('token');

        this.admAddr = process.env.VUE_APP_ADM_ADDR;

        if(this.user==null) {
            // await this.getPinSession();
            await this.getUser();
        }

    },
    methods: {
        onCopy: function (e) {
            this.$refs.toastx.toastbox('toast-msg-primary','Address copied', 3000)
            console.log(e.text)
            // alert('You just copied: ' + e.text)
        },
        onError: function (e) {
            this.$refs.toastx.toastbox('toast-msg-danger', 'Failed to copy address', 3000)
            // alert('Failed to copy texts')
            console.log(e)
        },
        countDownTimer() {
            if (this.countDownOTP > 0) {
                setTimeout(() => {
                    this.countDownOTP -= 1
                    // console.log(this.countDownOTP)
                    this.otpsendtext = this.countDownOTP + "s ";
                    this.countDownTimer()
                }, 1000)
            } else {
                this.otpsenddisabled = false;
                this.otpsendtext = "Get OTP";
            }
        },
        async doMigration() {
            // console.log(this.otp_disabled);
            try {
                this.loading = true
                const response = await axios.post('/postexportqueue', {
                    idminer: this.idminer,
                    password: this.password,
                    keycode: this.keycode
                });
                
                let res = response.data;
                if (res.status == 'success') {
                    await this.getUser();

                    this.$refs.toastx.toastbox('toast-msg-primary', res.message, 3000)
                    // await this.getUser();
                    // this.$router.push('/home')
                } else {
                    this.$refs.toastx.toastbox('toast-msg-danger', res.message, 3000)
                }
                this.loading = false
            } catch (error) {
                this.loading = false
                this.$refs.toastx.toastbox('toast-msg-danger', 'do migration failed!', 3000)
                console.log(error);
                // return res.json({"result": "failed", "txid": null, "status": error});
            }
        },
        async doPrivateKey() {
            // console.log(this.otp_disabled);
            try {
                this.loading = true
                const response = await axios.post('/showuserkey', {
                    idminer: this.idminerx,
                    password: this.passwordx,
                    keycode: this.keycode
                });

                let res = response.data;
                if (res.status == 'success') {
                    this.addrkey = res.data
                    await this.getUser();

                    this.$refs.toastx.toastbox('toast-msg-primary', res.message, 3000)
                    // await this.getUser();
                    // this.$router.push('/home')
                } else {
                    this.$refs.toastx.toastbox('toast-msg-danger', res.message, 3000)
                }
                this.loading = false
            } catch (error) {
                this.loading = false
                this.$refs.toastx.toastbox('toast-msg-danger', 'Show key failed!', 3000)
                console.log(error);
                // return res.json({"result": "failed", "txid": null, "status": error});
            }
        },
        async getUser() {
            // if(localStorage.getItem('token')) {
                // console.log('get user')
                try {
                    let response = await axios.get('getcodestatus/' +this.keycode+ '/' + process.env.VUE_APP_CODE);

                    let res = response.data;
                    // console.log('user : ', res)
                    if(res.user) {
                        // if (res.user.exportstatus == 'exported') {
                        //     this.$router.push('/pinverification')
                        // }

                        this.user = res.user;
                        this.tnotif = res.totnotif;
                        this.samount = res.samount;
                    } else {
                        // localStorage.removeItem('token');
                        // this.$router.push('/login')
                    }
                } catch (error) {
                    if (error.response.status === 401) {
                        // localStorage.removeItem('token');
                        this.$router.push('/login')
                    }
                }
            // } else {
            //     this.$router.push('/login')
            // }    
        },
        toDate(dt) {
            var date = new Date(dt);

            return date.getDate()+
                "/"+(date.getMonth()+1)+
                "/"+date.getFullYear()+
                " "+date.getHours()+
                ":"+date.getMinutes()+
                ":"+date.getSeconds();
        }
    },
}
</script>

<style scoped>
.text-responsive {
  font-size: calc(23% + 1vw + 1vh);
}
</style>