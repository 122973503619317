import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress';
// import Home from '../views/Home.vue'
import Package from '../views/Package.vue'
import Staking from '../views/Staking.vue'
import Stakinghistories from '../views/Stakinghistories.vue'
import Wallet from '../views/Wallet.vue'
import Walletdetail from '../views/Walletdetail.vue'
import Reward from '../views/Reward.vue'
import Login from '../views/Login.vue'
import Notif from '../views/Notif.vue'
import Notifdetail from '../views/Notifdetail.vue'
import Burnedhistories from '../views/Burnedhistories.vue'
import Twofactor from '../views/Twofactor.vue'
import Pin from '../views/Pin.vue'
import Pinverification from '../views/Pinverification.vue'
import Exportsc from '../views/Exportsc.vue'
import Showaddrkey from '../views/Showaddrkey.vue'
// import Scan from '../views/Scan.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Exportsc
  },
  {
    path: '/staking',
    name: 'Staking',
    component: Staking
  },
  {
    path: '/stakinghistories',
    name: 'Stakinghistories',
    component: Stakinghistories
  },
  {
    path: '/burnedhistories',
    name: 'Burnedhistories',
    component: Burnedhistories
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet
  },
  {
    path: '/walletdetail/:txid',
    name: 'Walletdetail',
    component: Walletdetail
  },
  {
    path: '/reward',
    name: 'Reward',
    component: Reward
  },
  {
    path: '/package',
    name: 'Package',
    component: Package
  },
  {
    path: '/2fa',
    name: 'Twofactor',
    component: Twofactor
  },
  {
    path: '/pin',
    name: 'Pin',
    component: Pin
  },
  {
    path: '/pinverification',
    name: 'Pinverification',
    component: Pinverification
  },
  {
    path: '/exporttosmartcontract',
    name: 'Exportsc',
    component: Exportsc
  },
  {
    path: '/showaddrkey/:keycode',
    name: 'Showaddrkey',
    component: Showaddrkey
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/notification',
    name: 'Notif',
    component: Notif
  },
  {
    path: '/notifdetail/:notifid',
    name: 'Notifdetail',
    component: Notifdetail
  },
  // {
  //   path: '/scan',
  //   name: 'Scan',
  //   component: Scan
  // },
  // {
  //   path: '/wallet',
  //   name: 'Wallet',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Wallet.vue')
  // },
  // {
  //   path: '/account',
  //   name: 'Account',
  //   component: () => import('../views/Account.vue')
  // },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import('../views/Login.vue')
  // }
]

const router = new VueRouter({
  // mode: 'history',
  linkExactActiveClass: 'active',
  routes
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
      // Start the route progress bar.
      NProgress.start()
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
