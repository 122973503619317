<template>
    <div>
        <Appheader v-if="user!=null" :idminer="user.idminer" :uname="user.name" :tnotif="tnotif" :samount="samount" :botid="user.botid" />

        <!-- App Capsule -->
        <div id="appCapsule">

            <!-- Wallet Card -->
            <div class="section wallet-card-section pt-1">
                <div class="wallet-card">
                    <div class="text-center">
                        <h2 class="title">Reward Available</h2>
                        <h1 class="total">{{totalreward}} DCT</h1>
                    </div>
                        <button class="btn btn-primary btn-lg btn-block" @click="doClaimReward">Claim Reward</button>
                </div>
            </div>
            <!-- Wallet Card -->

            <!-- Transactions -->
            <!-- <div class="section mt-4 mb-5">
                <div class="section-heading">
                    <h2 class="title">Reward Histories</h2>
                </div>
                <div class="transactions">
                    <a href="#" class="item" v-for="item in this.histories" :key="item.id">
                        <div class="detail">
                            <div>
                                <strong>{{item.description}}</strong>
                                <p>{{item.created_at}}</p>
                            </div>
                        </div>
                        <div class="right tright">
                            <div v-if="item.amountin!=null" class="price text-success">+ {{parseFloat(item.amountin)}} DCT</div>
                            <div v-if="item.amountout!=null" class="price text-danger">- {{parseFloat(item.amountout)}} DCT</div>
                        </div>
                    </a>
                </div>
            </div> -->
            <!-- * Transactions -->

        </div>
        <!-- * App Capsule -->        

        <Toast ref="toastx" />

        <Appbottom />

    </div>
</template>

<script>
const TronWeb = require('tronweb')
import axios from 'axios'
import Appheader from '../components/Appheader'
import Appbottom from '../components/Appbottom'
import Toast from '../components/Toast'

export default {
    name: 'Staking',
    components: {
        Appheader,
        Appbottom,
        Toast
    },
    // props:['user'],
    data() {
        return {
            user: null,
            token: null,
            contractAddr: 'TUKxxRkFi21d6KnqUi7aNsbA2So91xMDFG',
            contractDec: 10 ** 18,
            addrkey: null,
            histories: null,
            totalreward: 0,
            minclaim: 0,
            tnotif: 0,
            samount: 0
        }
    },
    async mounted() {
        this.$router.push('/exporttosmartcontract')
        // // console.log('mounted')
        // if(!localStorage.getItem('token')) {
        // this.$router.push('/login');
        // }

        // this.token = localStorage.getItem('token');

        // if(this.user==null) {
        //     await this.getUser();
        // }

        // if(this.user!=null){
        //     // await this.getLastStaking();
        //     // await this.getStakingHistories();
        //     await this.getHistories();
        // } 
    },
    methods: {
        getTronWeb() {
            this.addrkey = process.env.VUE_APP_PRIV_KEY;
            return new Promise((resolve, reject) => {
                const tronWeb = new TronWeb({
                    fullHost: process.env.VUE_APP_HOST,
                    // headers: { "TRON-PRO-API-KEY": process.env.VUE_APP_HEADER_KEY },
                    privateKey: this.addrkey
                });
                tronWeb ? resolve(tronWeb) : reject('TronWeb not found');
            });
        },
        getbalance(){
            this.getTronWeb().then(async (tronWeb) => {
            // console.log(this.user.addr)
            var getbal = await tronWeb.trx.getBalance(this.user.addr);
            // console.log('getbal', getbal)
            this.trxbalance =  tronWeb.fromSun(getbal);
            });
        },
        gettokenbalance(){
            this.getTronWeb().then(async (tronWeb) => {
            // console.log(this.user.addr)
            var contract = await tronWeb.contract().at(process.env.VUE_APP_CONTRACT);
            var tbal = await contract.balanceOf(this.user.addr).call();
            this.tokenbalance =  tronWeb.toBigNumber(tbal)/this.contractDec;
            });
        },
        async getHistories() {
            try {
                let response = await axios.get('/getrewards', {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                });
                // console.log(response.data)

                this.histories = response.data.success;
                this.minclaim = response.data.minclaim;
                if (this.histories.length > 0) {
                    this.totalreward = this.histories[0].available
                } else {
                    this.totalreward = 0
                }

            } catch (error) {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    this.$router.push('/login')
                }
            }
        },
        async getUser() {
            if(localStorage.getItem('token')) {
                // console.log('get user')
                try {
                    if (localStorage.getItem('sescode') == null) {
                        this.$router.push('/pinverification');
                    } else {
                        let response = await axios.get('user/' + process.env.VUE_APP_CODE + '/' + localStorage.getItem('sescode'), {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token')
                            }
                        });

                        let res = response.data;
                        // console.log(res)
                        if (res.user) {
                            // if pin not set
                            if (res.user.pin_created == null) {
                                this.$router.push('/pin')
                            }
                            // if pin session not active
                            if (res.sesstatus != 'active') {
                                localStorage.removeItem('sescode');
                                this.$router.push('/pinverification');
                            }
                            this.user = res.user;
                            this.tnotif = res.totnotif;
                            this.samount = res.samount;
                        } else {
                            localStorage.removeItem('token');
                            this.$router.push('/login')
                        }
                    }
                    
                } catch (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('token');
                        this.$router.push('/login')
                    }
                }
            } else {
                this.$router.push('/login')
            }    
        },
        async doClaimReward(){
            var amount = parseFloat(this.totalreward);
            if(amount<=this.minclaim) {
                this.$refs.toastx.toastbox('toast-msg-danger', 'Minimum claim '+this.minclaim+' DCT!', 3000)
            } else {
                try {
                    const response = await axios.post('/postrewardteam', {
                        amount: this.totalreward
                    }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }});
                    let res = response.data;
                    if(res.status=='success') {
                        this.getHistories();
                        this.$refs.toastx.toastbox('toast-msg-primary','Claim waiting for confirmation!', 3000);
                    } else {
                        this.getHistories();
                        this.$refs.toastx.toastbox('toast-msg-danger', res.error, 3000)
                    }
                } catch (error) {
                    this.getHistories();
                    this.$refs.toastx.toastbox('toast-msg-danger', 'Claim token failed!', 3000)
                    console.log(error);
                }
            }
        },
        toDate(dt) {
            var date = new Date(dt);

            return date.getDate()+
                "/"+(date.getMonth()+1)+
                "/"+date.getFullYear()+
                " "+date.getHours()+
                ":"+date.getMinutes()+
                ":"+date.getSeconds();
        }
    },
}
</script>

<style scoped>
.text-responsive {
  font-size: calc(23% + 1vw + 1vh);
}
</style>