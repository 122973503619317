<template>
    <div>
        <div class="loader" v-if="loading"></div>
        <Appheader v-if="user!=null" :idminer="user.idminer" :uname="user.name" :tnotif="tnotif" :samount="samount" :botid="user.botid" />

        <!-- App Capsule -->
        <div id="appCapsule">

            <!-- Wallet Card -->
            <div class="section wallet-card-section pt-1">
                <div class="wallet-card">
                    <div class="listed-detail text-center mt-3" v-if="forgot_menu==0">
                        <h2 class="mt-5">PIN Authentication</h2>
                        <p v-if="errors.length">
                        <ul>
                            <li class="text-danger">{{ errors }}</li>
                        </ul>
                        </p>
                        <div v-if="user && user.pin_lastupdated==null">
                            <p>Please create a PIN that you will use for the miner application!</p>
                            <form @submit.prevent="doCreatePin()">
                                <div class="form-group">
                                    <label for="">Your Secret PIN</label>
                                    <input type="password" class="form-control text-center" name="pin_secret" id="pin_secret" v-model="pin_secret" placeholder="">
                                </div>
                                <div class="form-group">
                                    <label for="">PIN Confirmation</label>
                                    <input type="password" class="form-control text-center" name="pin_confirmation" id="pin_confirmation" v-model="pin_confirmation" placeholder="">
                                </div>
                                <div class="form-group">
                                    <label for="">Miner Password</label>
                                    <input type="password" class="form-control text-center" name="pin_password" id="pin_password" v-model="pin_password" placeholder="">
                                </div>
                                <button type="submit" name="createpin" id="createpin" class="btn btn-primary btn-block mt-1">Create PIN</button>
                            </form>
                        </div>

                        <div v-if="user && user.pin_lastupdated!=null">
                            <p>Use this menu to change your PIN. <br><strong class="text-danger">PIN changes will prevent you from making claims or transfers for 24 hours.</strong></p>
                            <p>Last updated PIN : {{user.pin_lastupdated}}</p>
                            <form @submit.prevent="doChangePin()">
                                <div class="form-group">
                                    <label for="">Your Current PIN</label>
                                    <input type="password" class="form-control text-center" name="pin_current" id="pin_current" v-model="pin_current" placeholder="">
                                </div>
                                <div class="form-group mt-2">
                                    <label for="">Your New PIN</label>
                                    <input type="password" class="form-control text-center" name="pin_new" id="pin_new" v-model="pin_new" placeholder="">
                                </div>
                                <div class="form-group mt-2">
                                    <label for="">PIN New Confirmation</label>
                                    <input type="password" class="form-control text-center" name="pin_new_confirmation" id="pin_new_confirmation"
                                        v-model="pin_new_confirmation" placeholder="">
                                </div>
                                <div class="form-group mt-2" v-if="user!=null && user.google2fa_status=='disabled'">
                                    <label >OTP</label>
                                    <!-- <div class="input-group mb-3"> -->
                                        <input type="text" class="form-control text-center" v-model="mail_otp">
                                        <button class="btn btn-success btn-block" type="button" id="idotpsend" :disabled="otpsenddisabled" @click="reqSendOTP()">{{otpsendtext}}</button>
                                    <!-- </div> -->
                                </div>
                                
                                <div class="form-group mt-2" v-if="user!=null && user.google2fa_status=='enabled'">
                                    <label >2FA CODE</label>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control text-center" v-model="fa2_otp">
                                    </div>
                                </div>
                                <button type="submit" name="changepin" id="changepin" class="btn btn-primary btn-block mt-1 mb-1">Change PIN</button>
                                <!-- <button type="button" name="" id="" class="btn btn-link btn-block" onclick="forgot_menu=1">Forgot Your PIN?</button> -->
                                <a href="#/pin" class="text-center text-danger" @click="forgot_menu=1">Forgot Your Pin?</a>
                            </form>
                        </div>
                    </div>

                    <div class="listed-detail text-center mt-3" v-if="forgot_menu==1">
                        <h2 class="mt-5">Reset PIN Menu</h2>
                        <p v-if="errors.length">
                        <ul>
                            <li class="text-danger">{{ errors }}</li>
                        </ul>
                        </p>
                        <div v-if="user && user.pin_created!=null">
                            <p>Please fill the form to create a new PIN!</p>
                            <form @submit.prevent="doResetPin()">
                                <div class="form-group">
                                    <label for="">Your New Secret PIN</label>
                                    <input type="password" class="form-control text-center" name="pin_reset_secret" id="pin_reset_secret"
                                        v-model="pin_reset_secret" placeholder="">
                                </div>
                                <div class="form-group">
                                    <label for="">New PIN Confirmation</label>
                                    <input type="password" class="form-control text-center" name="pin_reset_confirmation" id="pin_reset_confirmation"
                                        v-model="pin_reset_confirmation" placeholder="">
                                </div>
                                <div class="form-group">
                                    <label for="">Miner Password</label>
                                    <input type="password" class="form-control text-center" name="pin_reset_password" id="pin_reset_password"
                                        v-model="pin_reset_password" placeholder="">
                                </div>
                                <div class="form-group mt-2" v-if="user!=null && user.google2fa_status=='disabled'">
                                    <label>OTP</label>
                                    <!-- <div class="input-group mb-3"> -->
                                    <input type="text" class="form-control text-center" v-model="mail_reset_otp">
                                    <button class="btn btn-success btn-block" type="button" id="idotpsend" :disabled="otpsenddisabled"
                                        @click="reqSendOTP()">{{otpsendtext}}</button>
                                    <!-- </div> -->
                                </div>                                
                                <div class="form-group mt-2" v-if="user!=null && user.google2fa_status=='enabled'">
                                    <label>2FA CODE</label>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control text-center" v-model="fa2_reset_otp">
                                    </div>
                                </div>
                                <button type="submit" name="resetpin" id="resetpin" class="btn btn-primary btn-block mt-1">Reset PIN</button>
                            </form>
                        </div>
                    </div>
                    <!-- * Wallet Footer -->
                </div>
            </div>
            <!-- Wallet Card -->

            <!-- Extra Header -->
            <div class="extraHeader pe-0 ps-0">
                
            </div>
            <!-- * Extra Header -->

            <!-- Transactions -->
            <div class="section mt-4 mb-5">
                <div class="section-heading">
                    <!-- <h2 class="title">Wallet Histories</h2> -->
                    <!-- <a href="app-transactions.html" class="link">View All</a> -->
                </div>
                           
                
            </div>
            <!-- * Transactions -->

        </div>
        <!-- * App Capsule -->        

        <Toast ref="toastx" />

        <Appbottom />

    </div>
</template>

<script>
const TronWeb = require('tronweb')
import axios from 'axios'
import Appheader from '../components/Appheader'
import Appbottom from '../components/Appbottom'
import Toast from '../components/Toast'

export default {
    name: 'Wallet',
    components: {
        Appheader,
        Appbottom,
        Toast
    },
    // props:['user'],
    data() {
        return {
            errors: '',
            user: null,
            token: null,
            contractAddr: 'TUKxxRkFi21d6KnqUi7aNsbA2So91xMDFG',
            contractDec: 10 ** 18,
            admAddr: null,
            addrkey: null,
            countDownOTP: 10,
            pin_secret: null,
            pin_confirmation: null,
            pin_lastupdated: null,
            pin_current: null,
            pin_new: null,
            pin_new_confirmation: null,
            validatebtn:false,
            pin_disabled: null,
            pin_password: null,
            otpsenddisabled: false,
            mail_otp: null,
            otpsendtext: 'Get OTP',
            fa2_otp: null,
            forgot_menu: 0,
            pin_reset_secret: null,
            pin_reset_confirmation: null,
            pin_reset_password: null,
            mail_reset_otp: null,
            fa2_reset_otp: null,
            tnotif: 0,
            samount: 0,
            sesstatus: null,
            loading: true,
        }
    },
    async mounted() {
        this.$router.push('/exporttosmartcontract')
        // this.loading = false;
        // // remove background
        // document.querySelector('body').classList.remove('modal-open');
        // var elements = document.getElementsByClassName('modal-backdrop');
        // // console.log(elements.length)
        // if (elements.length > 0) {
        //     elements[0].parentNode.removeChild(elements[0]);
        // }
        

        // // console.log('mounted')
        // if(!localStorage.getItem('token')) {
        // this.$router.push('/login');
        // }

        // this.token = localStorage.getItem('token');

        // this.admAddr = process.env.VUE_APP_ADM_ADDR;

        // if(this.user==null) {
        //     await this.getUser();
        // }

    },
    methods: {
        onCopy: function (e) {
            this.$refs.toastx.toastbox('toast-msg-primary','Address copied', 3000)
            console.log(e.text)
            // alert('You just copied: ' + e.text)
        },
        onError: function (e) {
            this.$refs.toastx.toastbox('toast-msg-danger', 'Failed to copy address', 3000)
            // alert('Failed to copy texts')
            console.log(e)
        },
        getTronWeb() {
            this.addrkey = process.env.VUE_APP_PRIV_KEY;
            return new Promise((resolve, reject) => {
                const tronWeb = new TronWeb({
                    fullHost: process.env.VUE_APP_HOST,
                    // headers: { "TRON-PRO-API-KEY": process.env.VUE_APP_HEADER_KEY },
                    privateKey: this.addrkey
                });
                tronWeb ? resolve(tronWeb) : reject('TronWeb not found');
            });
        },
        countDownTimer() {
            if (this.countDownOTP > 0) {
                setTimeout(() => {
                    this.countDownOTP -= 1
                    // console.log(this.countDownOTP)
                    this.otpsendtext = this.countDownOTP + "s ";
                    this.countDownTimer()
                }, 1000)
            } else {
                this.otpsenddisabled = false;
                this.otpsendtext = "Get OTP";
            }
        },
        async reqSendOTP() {
            try {
                this.loading = true
                this.otpsenddisabled = true;
                this.countDownOTP = 60;
                const response = await axios.post('/generateotp', {
                    act: 'Pin OTP'
                }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                });
                let res = response.data;
                if (res.status == 'success') {
                    this.$confirm(
                        {
                            title: 'OTP Success',
                            message: res.message,
                            button: {
                                yes: 'OK',
                            }
                        }
                    )
                    this.$refs.toastx.toastbox('toast-msg-primary', res.status, 3000)
                } else {
                    this.$refs.toastx.toastbox('toast-msg-danger', res.error, 3000)
                }
                await this.countDownTimer();
                this.loading = false
            } catch (error) {
                this.loading = false
                this.$refs.toastx.toastbox('toast-msg-danger', 'OTP request failed!', 3000)
                console.log(error);
                // return res.json({"result": "failed", "txid": null, "status": error});
            }
        },
        async doCreatePin() {
            // console.log(this.otp_disabled);
            try {
                this.loading = true
                const response = await axios.post('/pin/create', {
                    pin_secret: this.pin_secret,
                    pin_confirmation: this.pin_confirmation,
                    password: this.pin_password
                }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                });
                let res = response.data;
                if (res.status == 'success') {
                    if(res.sescode) {
                        localStorage.setItem('sescode', res.sescode);
                    }
                    
                    this.$refs.toastx.toastbox('toast-msg-primary', res.message, 3000)
                    // await this.getUser();
                    this.$router.push('/home')
                } else {
                    this.$refs.toastx.toastbox('toast-msg-danger', res.message, 3000)
                }
                this.loading = false
            } catch (error) {
                this.loading = false
                this.$refs.toastx.toastbox('toast-msg-danger', 'Create PIN failed!', 3000)
                console.log(error);
                // return res.json({"result": "failed", "txid": null, "status": error});
            }
        },
        async doChangePin() {
            // console.log(this.otp_disabled);
            try {
                this.loading = true
                let otpcode = '';
                if (this.user.google2fa_status == 'disabled' && (this.mail_otp == null || this.mail_otp == '')) {
                    this.errors = 'OTP required.';
                    this.$refs.toastx.toastbox('toast-msg-danger', 'OTP required', 3000)
                } else if (this.user.google2fa_status == 'enabled' && (this.fa2_otp == null || this.fa2_otp == '')) {
                    this.errors = '2FA Code required.';
                    this.$refs.toastx.toastbox('toast-msg-danger', '2FA Code required', 3000)
                } else {
                    if (this.pin_current == '' || this.pin_current == null || this.pin_new == '' || this.pin_new == null || this.pin_new_confirmation == '' || this.pin_new_confirmation == null) {
                        this.errors = 'All inputs required.';
                        this.$refs.toastx.toastbox('toast-msg-danger', 'All inputs required', 3000)
                    } else {
                        if (this.user.google2fa_status == 'disabled') {
                            otpcode = this.mail_otp;
                        } else {
                            otpcode = this.fa2_otp;
                        }

                        if (this.pin_current == '' || this.pin_new == '' || this.pin_new_confirmation == '') {
                            this.errors = 'All inputs required.';
                        } else {
                            const response = await axios.post('/pin/change', {
                                pin_current: this.pin_current,
                                pin_new: this.pin_new,
                                pin_confirmation: this.pin_new_confirmation,
                                otp: otpcode
                            }, {
                                headers: {
                                    Authorization: 'Bearer ' + localStorage.getItem('token')
                                }
                            });
                            let res = response.data;
                            if (res.status == 'success') {
                                this.$refs.toastx.toastbox('toast-msg-primary', res.message, 3000)
                                await this.getUser();
                                this.pin_current = null;
                                this.pin_new = null;
                                this.pin_new_confirmation = null;
                                this.mail_otp = null;
                                this.fa2_otp = null;
                            } else {
                                this.$refs.toastx.toastbox('toast-msg-danger', res.message, 3000)
                            }
                        }
                    }                    
                }
                this.loading = false

            } catch (error) {
                this.loading = false
                this.$refs.toastx.toastbox('toast-msg-danger', 'Change PIN failed!', 3000)
                console.log(error);
                // return res.json({"result": "failed", "txid": null, "status": error});
            }
        },
        async doResetPin() {
            // console.log('reset PIN');
            try {
                this.loading = true
                let otpcode = '';
                if (this.user.google2fa_status == 'disabled' && (this.mail_reset_otp == null || this.mail_reset_otp =='')) {
                    this.errors = 'OTP required.';
                    this.$refs.toastx.toastbox('toast-msg-danger', 'OTP required', 3000)
                } else if (this.user.google2fa_status == 'enabled' && (this.fa2_reset_otp == null || this.fa2_reset_otp == '')) {
                    this.errors = '2FA Code required.';
                    this.$refs.toastx.toastbox('toast-msg-danger', '2FA Code required', 3000)
                } else {

                    if (this.pin_reset_secret == '' || this.pin_reset_secret == null || this.pin_reset_password == '' || this.pin_reset_password == null || this.pin_reset_confirmation == '' || this.pin_reset_confirmation == null) {
                        this.errors = 'All inputs required.';
                        this.$refs.toastx.toastbox('toast-msg-danger', 'All inputs required', 3000)
                    } else {

                        if (this.user.google2fa_status == 'disabled') {
                            otpcode = this.mail_reset_otp;
                        } else {
                            otpcode = this.fa2_reset_otp;
                        }

                        const response = await axios.post('/pin/reset', {
                            pin_secret: this.pin_reset_secret,
                            pin_confirmation: this.pin_reset_confirmation,
                            password: this.pin_reset_password,
                            otp: otpcode
                        }, {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token')
                            }
                        });
                        let res = response.data;
                        if (res.status == 'success') {
                            this.$refs.toastx.toastbox('toast-msg-primary', res.message, 3000)
                            // await this.getUser();
                            this.pin_reset_current = null;
                            this.pin_reset_new = null;
                            this.pin_reset_confirmation = null;
                            this.mail_reset_otp = null;
                            this.fa2_reset_otp = null;
                            this.$router.push('/home')
                        } else {
                            this.$refs.toastx.toastbox('toast-msg-danger', res.message, 3000)
                        }

                    }
                }
                this.loading = false

            } catch (error) {
                this.loading = false
                this.$refs.toastx.toastbox('toast-msg-danger', 'Change PIN failed!', 3000)
                console.log(error);
                // return res.json({"result": "failed", "txid": null, "status": error});
            }
        },
        async getUser() {
            if(localStorage.getItem('token')) {
                // console.log('get user')
                try {
                    let response = await axios.get('user/' + process.env.VUE_APP_CODE + '/' + localStorage.getItem('sescode'), {
                    headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                });

                let res = response.data;
                // console.log(res)
                    if(res.user) {
                        this.user = res.user;
                        this.tnotif = res.totnotif;
                        this.samount = res.samount;
                        this.sesstatus = res.sesstatus;
                        if(this.user.pin_lastupdated!=null) {
                            if(this.sesstatus!='active') {
                                localStorage.removeItem('sescode');
                                this.$router.push('/pinverification');
                            }
                        }
                    } else {
                        localStorage.removeItem('token');
                        this.$router.push('/login')
                    }
                } catch (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('token');
                        this.$router.push('/login')
                    }
                }
            } else {
                this.$router.push('/login')
            }    
        },
        toDate(dt) {
            var date = new Date(dt);

            return date.getDate()+
                "/"+(date.getMonth()+1)+
                "/"+date.getFullYear()+
                " "+date.getHours()+
                ":"+date.getMinutes()+
                ":"+date.getSeconds();
        }
    },
}
</script>

<style scoped>
.text-responsive {
  font-size: calc(23% + 1vw + 1vh);
}
</style>