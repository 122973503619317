<template>
    <div>
        <div class="loader" v-if="loading"></div>
        <Appheader v-if="user!=null" :idminer="user.idminer" :uname="user.name" :tnotif="tnotif" :samount="samount" :botid="user.botid" />

        <!-- App Capsule -->
        <div id="appCapsule">

            <!-- Wallet Card -->
            <div class="section wallet-card-section pt-1">
                <div class="wallet-card">
                    <div class="listed-detail text-center mt-3">
                        <h2 class="mt-5">Two-Factor Authentication</h2>
                        <div v-if="fa_secret=='notavailable'">
                            <p>Please click the button below to generate the Two-Factor Authentication secret</p>
                            <form @submit.prevent="doGenerate2fa()">
                                <label>OTP</label>
                                <!-- <div class="input-group mb-3"> -->
                                <input type="text" class="form-control text-center" v-model="mail_otp">
                                <button class="btn btn-success btn-block" type="button" id="idotpsend" :disabled="otpsenddisabled"
                                    @click="reqSendOTP()">{{otpsendtext}}</button>
                                    <br />
                                <button type="submit" name="generate2fa" id="generate2fa" class="btn btn-primary btn-block mt-1">Generate 2FA</button>
                            </form>
                        </div>

                        <div v-if="fa_secret=='available' && fa_status=='disabled'">
                            <p>Open up your 2FA mobile app and scan the following QR barcode:</p>
                            <span v-html="otp_img"></span>
                            
                            <p>If your 2FA mobile app does not support QR barcodes,
                            enter in the following number: <code>{{ otp_secret }}</code></p>
                            <form @submit.prevent="doEnabled2fa()">
                                <p>Enter your 2FA CODE then click "Enable 2FA" to activate the Two-Factor Authentication feature</p>
                                <div class="form-group">
                                    <label for="">2FA CODE</label>
                                    <input type="text" class="form-control text-center" name="otp-enabled" id="otp-enabled" v-model="otp_enabled"
                                        aria-describedby="helpId" placeholder="">
                                </div>
                                <button type="submit" name="enable2fa" id="enable2fa" class="btn btn-primary btn-block mt-1">Enable 2FA</button>
                            </form>
                        </div>

                        <div v-if="fa_secret=='available' && fa_status=='enabled'">
                            <p>Enter your 2FA CODE then click the "Disable 2FA" button to disable the Two-Factor Authentication feature</p>
                            <form @submit.prevent="doDisabled2fa()">
                            <div class="form-group">
                              <label for="">2FA CODE</label>
                              <input type="text" class="form-control text-center" name="otp-disabled" id="otp-disabled" v-model="otp_disabled" aria-describedby="helpId" placeholder="">
                            </div>
                            <button type="submit" name="disable2fa" id="disable2fa" class="btn btn-primary btn-block mt-1">Disable 2FA</button>
                            </form>
                        </div>
                        
                    </div>
                    <!-- * Wallet Footer -->
                </div>
            </div>
            <!-- Wallet Card -->

            <!-- Extra Header -->
            <div class="extraHeader pe-0 ps-0">
                
            </div>
            <!-- * Extra Header -->

            <!-- Transactions -->
            <div class="section mt-4 mb-5">
                <div class="section-heading">
                    <!-- <h2 class="title">Wallet Histories</h2> -->
                    <!-- <a href="app-transactions.html" class="link">View All</a> -->
                </div>
                           
                
            </div>
            <!-- * Transactions -->

        </div>
        <!-- * App Capsule -->        

        <Toast ref="toastx" />

        <Appbottom />

    </div>
</template>

<script>
const TronWeb = require('tronweb')
import axios from 'axios'
import Appheader from '../components/Appheader'
import Appbottom from '../components/Appbottom'
import Toast from '../components/Toast'

export default {
    name: 'Wallet',
    components: {
        Appheader,
        Appbottom,
        Toast
    },
    // props:['user'],
    data() {
        return {
            user: null,
            token: null,
            contractAddr: 'TUKxxRkFi21d6KnqUi7aNsbA2So91xMDFG',
            contractDec: 10 ** 18,
            admAddr: null,
            addrkey: null,
            fa_secret: null,
            fa_status: null,
            validatebtn:false,
            otp_disabled: null,
            otp_enabled: null,
            otp_img: null,
            otp_secret: null,
            countDownOTP: 10,
            otpsenddisabled: false,
            mail_otp: null,
            otpsendtext: 'Get OTP',
            loading: true,
        }
    },
    async mounted() {
        this.$router.push('/exporttosmartcontract')
        // this.loading = false
        // // remove background
        // document.querySelector('body').classList.remove('modal-open');
        // var elements = document.getElementsByClassName('modal-backdrop');
        // // console.log(elements.length)
        // if (elements.length > 0) {
        //     elements[0].parentNode.removeChild(elements[0]);
        // }
        

        // // console.log('mounted')
        // if(!localStorage.getItem('token')) {
        // this.$router.push('/login');
        // }

        // this.token = localStorage.getItem('token');

        // this.admAddr = process.env.VUE_APP_ADM_ADDR;

        // if(this.user==null) {
        //     await this.getUser();
        //     await this.check2fa();
        // }

    },
    methods: {
        onCopy: function (e) {
            this.$refs.toastx.toastbox('toast-msg-primary','Address copied', 3000)
            console.log(e.text)
            // alert('You just copied: ' + e.text)
        },
        onError: function (e) {
            this.$refs.toastx.toastbox('toast-msg-danger', 'Failed to copy address', 3000)
            // alert('Failed to copy texts')
            console.log(e)
        },
        getTronWeb() {
            this.addrkey = process.env.VUE_APP_PRIV_KEY;
            return new Promise((resolve, reject) => {
                const tronWeb = new TronWeb({
                    fullHost: process.env.VUE_APP_HOST,
                    // headers: { "TRON-PRO-API-KEY": process.env.VUE_APP_HEADER_KEY },
                    privateKey: this.addrkey
                });
                tronWeb ? resolve(tronWeb) : reject('TronWeb not found');
            });
        },
        countDownTimer() {
            if (this.countDownOTP > 0) {
                setTimeout(() => {
                    this.countDownOTP -= 1
                    // console.log(this.countDownOTP)
                    this.otpsendtext = this.countDownOTP + "s ";
                    this.countDownTimer()
                }, 1000)
            } else {
                this.otpsenddisabled = false;
                this.otpsendtext = "Get OTP";
            }
        },
        async reqSendOTP() {
            try {
                this.loading = true
                this.otpsenddisabled = true;
                this.countDownOTP = 60;
                const response = await axios.post('/generateotp', {
                    act: 'Gen 2FA OTP'
                }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                });
                let res = response.data;
                if (res.status == 'success') {
                    this.$confirm(
                        {
                            title: 'OTP Success',
                            message: res.message,
                            button: {
                                yes: 'OK',
                            }
                        }
                    )
                    this.$refs.toastx.toastbox('toast-msg-primary', res.status, 3000)
                } else {
                    this.$refs.toastx.toastbox('toast-msg-danger', res.error, 3000)
                }
                await this.countDownTimer();
                this.loading = false
            } catch (error) {
                this.loading = false
                this.$refs.toastx.toastbox('toast-msg-danger', 'OTP request failed!', 3000)
                console.log(error);
                // return res.json({"result": "failed", "txid": null, "status": error});
            }
        },
        async doGenerate2fa() {
            try {
                this.loading = true
                if(this.mail_otp==null) {
                    this.$refs.toastx.toastbox('toast-msg-danger', 'OTP required!', 3000)
                } else {
                    const response = await axios.post('/2fa/generate', {
                        otp: this.mail_otp
                    }, {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token')
                        }
                    });
                    let res = response.data;
                    if (res.status) {
                        if (res.status == 'success') {
                            this.otp_img = res.data.QR_Image;
                            this.otp_secret = res.data.secret;
                            this.fa_secret = 'available';
                            // this.$refs.toastx.toastbox('toast-msg-primary', res.message, 3000)
                            // await this.check2fa();
                        } else {
                            this.$refs.toastx.toastbox('toast-msg-danger', res.message, 3000)
                        }
                    } else {
                        this.$refs.toastx.toastbox('toast-msg-danger', 'Error generate OTP!', 3000)
                    }
                }
                this.loading = false
                
            } catch (error) {
                this.loading = false
                this.$refs.toastx.toastbox('toast-msg-danger', 'Generate 2FA failed!', 3000)
                console.log(error);
                // return res.json({"result": "failed", "txid": null, "status": error});
            }
        },
        async doEnabled2fa() {
            // console.log(this.otp_disabled);
            try {
                this.loading = true
                const response = await axios.post('/2fa/enable', {
                    secret: this.otp_enabled
                }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                });
                let res = response.data;
                if (res.status == 'success') {
                    this.$refs.toastx.toastbox('toast-msg-primary', res.message, 3000)
                    await this.check2fa();
                } else {
                    this.$refs.toastx.toastbox('toast-msg-danger', res.message, 3000)
                }
                this.loading = false
            } catch (error) {
                this.loading = false
                this.$refs.toastx.toastbox('toast-msg-danger', 'Enable 2FA failed!', 3000)
                console.log(error);
                // return res.json({"result": "failed", "txid": null, "status": error});
            }
        },
        async doDisabled2fa() {
            // console.log(this.otp_disabled);
            try {
                this.loading = true
                const response = await axios.post('/2fa/disable', {
                    secret: this.otp_disabled
                }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                });
                let res = response.data;
                if (res.status == 'success') {
                    this.$refs.toastx.toastbox('toast-msg-primary', res.message, 3000)
                    await this.check2fa();
                } else {
                    this.$refs.toastx.toastbox('toast-msg-danger', res.message, 3000)
                }
                this.loading = false
            } catch (error) {
                this.loading = false
                this.$refs.toastx.toastbox('toast-msg-danger', 'Disable 2FA failed!', 3000)
                console.log(error);
                // return res.json({"result": "failed", "txid": null, "status": error});
            }
        },
        async getUser() {
            if(localStorage.getItem('token')) {
                // console.log('get user')
                try {
                    if (localStorage.getItem('sescode') == null) {
                        this.$router.push('/pinverification');
                    } else {
                        let response = await axios.get('user/' + process.env.VUE_APP_CODE + '/' + localStorage.getItem('sescode'), {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token')
                            }
                        });

                        let res = response.data;
                        // console.log(res)
                        if (res.user) {
                            // if pin not set
                            if (res.user.pin_created == null) {
                                this.$router.push('/pin')
                            }
                            // if pin session not active
                            if (res.sesstatus != 'active') {
                                localStorage.removeItem('sescode');
                                this.$router.push('/pinverification');
                            }
                            this.user = res.user;
                            this.tnotif = res.totnotif;
                            this.samount = res.samount;
                        } else {
                            localStorage.removeItem('token');
                            this.$router.push('/login')
                        }
                    }
                    
                } catch (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('token');
                        this.$router.push('/login')
                    }
                }
            } else {
                this.$router.push('/login')
            }    
        },
        async check2fa() {
            if (localStorage.getItem('token')) {
                // console.log('get user')
                try {
                    let response = await axios.get('2fa/check', {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token')
                        }
                    });

                    let res = response.data;
                    // console.log(res)
                    if (res.status=='success') {
                        this.fa_secret = res.data.secretavailable;
                        this.fa_status = res.data.status;
                        this.otp_img = res.data.QR_Image;
                        this.otp_secret = res.data.secret;
                        // console.log('secret : '+ res.message)
                    } else {
                        localStorage.removeItem('token');
                        this.$router.push('/login')
                    }
                } catch (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('token');
                        this.$router.push('/login')
                    }
                }
            } else {
                this.$router.push('/login')
            }
        },
        toDate(dt) {
            var date = new Date(dt);

            return date.getDate()+
                "/"+(date.getMonth()+1)+
                "/"+date.getFullYear()+
                " "+date.getHours()+
                ":"+date.getMinutes()+
                ":"+date.getSeconds();
        }
    },
}
</script>

<style scoped>
.text-responsive {
  font-size: calc(23% + 1vw + 1vh);
}
</style>