<template>
  <div id="app">
      <!-- <router-view :user="user" @user-change="userChange" @user-info-change="userInfoChange"></router-view>     -->
      <router-view></router-view>    
      <vue-confirm-dialog></vue-confirm-dialog>
  </div>
</template>

<script>
// import axios from 'axios'

export default {
  name: 'App',
    data: () => ({ 
      user: null
    }),
    async mounted() {
      // // const response = await axios.get('user', {
      // //   headers: {
      // //     Authorization: 'Bearer ' + localStorage.getItem('token')
      // //   }
      // // });
      // if(localStorage.getItem('token')) {
      //   try {
      //     let response = await axios.get('user', {
      //       headers: {
      //         Authorization: 'Bearer ' + localStorage.getItem('token')
      //       }
      //     });

      //     let res = response.data;
      //     console.log(res)
      //     this.user = res.user;
      //   } catch (error) {
      //     if (error.response.status === 401) {
      //         localStorage.removeItem('token');
      //         this.$router.push('/login')
      //     }
      //   }
      // }      
    },
    methods: {
      // userChange: async function(token) {
      //   // console.log('token', token)
      //   try {
      //     let response = await axios.get('user', {
      //       headers: {
      //         Authorization: 'Bearer ' + token
      //       }
      //     });

      //     let res = response.data;
      //     // console.log(res)
      //     this.user = res.user;
      //   } catch (error) {
      //     if (error.response.status === 401) {
      //         localStorage.removeItem('token');
      //         this.$router.push('/login')
      //     }
      //   }
        
      // },
      // userInfoChange: async function(user) {
      //     // console.log('user info change')
      //     this.user = user;
        
      // }
    }
}
</script>

<style>

</style>
