<template>
        <div>
        <!-- App Header -->
        <div class="appHeader bg-primary text-light">
            <div class="left">
                <a href="#" class="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                    <ion-icon name="menu-outline"></ion-icon>
                </a>
            </div>
            <div class="pageTitle">
                <!-- <img src="/assets/img/logo.png" alt="logo" class="logo"> -->
                DCT MINER
            </div>
            <div class="right">
                <a href="#" class="headerButton">
                    <ion-icon class="icon" name="refresh-outline" @click="reloadpage"></ion-icon>
                </a>
                <router-link to="notification" class="headerButton">
                    <ion-icon class="icon" name="notifications-outline"></ion-icon>
                    <span v-if="tnotif>0" class="badge badge-danger">{{tnotif}}</span>
                </router-link>
                <!-- <a href="#" class="headerButton">
                    <img src="/assets/img/sample/avatar/avatar1.jpg" alt="image" class="imaged w32">
                    <span class="badge badge-danger">6</span>
                </a> -->
            </div>
        </div>
        <!-- * App Header -->

        <!-- App Sidebar -->
        <div class="modal fade panelbox panelbox-left" id="sidebarPanel" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body p-0">
                        <!-- profile box -->
                        <div class="profileBox pt-2 pb-2">
                            <!-- <div class="image-wrapper">
                                <img src="/assets/img/sample/avatar/avatar1.jpg" alt="image" class="imaged  w36">
                            </div> -->
                            <div class="in">
                                <strong>{{uname}}</strong>
                                <div class="text-muted">{{idminer}}</div>
                                <div class="text-muted">{{botid}}</div>
                            </div>
                            <a href="#" class="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal">
                                <ion-icon name="close-outline"></ion-icon>
                            </a>
                        </div>
                        <!-- * profile box -->
                        <!-- balance -->
                        <div class="sidebar-balance">
                            <div class="listview-title">Staking</div>
                            <div class="in">
                                <h1 class="amount">{{samount}} DCT</h1>
                            </div>
                        </div>
                        <!-- * balance -->

                        <!-- others -->
                        <div class="listview-title mt-1">Others</div>
                        <ul class="listview flush transparent no-line image-listview">
                            <li>
                                <a href="#/pin" class="item">
                                    <div class="icon-box bg-primary">
                                        <ion-icon name="key-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        Transaction PIN
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#/2fa" class="item">
                                    <div class="icon-box bg-primary">
                                        <ion-icon name="key-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        2FA
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="item">
                                    <div class="icon-box bg-primary">
                                        <ion-icon name="settings-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        <div>
                                            Dark Mode
                                        </div>
                                        <div class="form-check form-switch  ms-2">
                                            <input class="form-check-input dark-mode-switch" type="checkbox" id="darkmodeSwitch" v-on:click="clickDark">
                                            <label class="form-check-label" for="darkmodeSwitch"></label>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <!-- <li>
                                <a href="#" class="item">
                                    <div class="icon-box bg-primary">
                                        <ion-icon name="settings-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        Settings
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="item">
                                    <div class="icon-box bg-primary">
                                        <ion-icon name="chatbubble-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        Support
                                    </div>
                                </a>
                            </li> -->
                            <li>
                                <a @click.prevent="logout" class="item">
                                    <div class="icon-box bg-primary">
                                        <ion-icon name="log-out-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        Log out
                                    </div>
                                </a>
                            </li>


                        </ul>
                        <!-- * others -->

                    </div>
                </div>
            </div>
        </div>
        <!-- * App Sidebar -->
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Appheader',
    components: {
    },
    props: ['idminer','uname', 'tnotif', 'samount', 'botid'],
    data: function () {
      return {
          checkDarkModeStatus: 0
      }
    },
    mounted() {
        this.checkDarkModeStatus = localStorage.getItem("FinappDarkmode");
        var pageBody = document.querySelector("body");
        var pageBodyActive = pageBody.classList.contains("dark-mode");
        if (this.checkDarkModeStatus === 1 || this.checkDarkModeStatus === "1") {
            this.switchDarkModeCheck(true);
            if (pageBodyActive) {
                // dark mode already activated
            }
            else {
                pageBody.classList.add("dark-mode")
            }
        }
        else {
            this.switchDarkModeCheck(false);
        }
    },
    methods: {
        switchDarkModeCheck(value) {
            var switchDarkMode = document.querySelectorAll(".dark-mode-switch");
            switchDarkMode.forEach(function (el) {
                el.checked = value
            })
        },
        clickDark() {
            var pageBody = document.querySelector("body");
            var darkmodeCheck = localStorage.getItem("FinappDarkmode");
            if (darkmodeCheck === 1 || darkmodeCheck === "1") {
                pageBody.classList.remove("dark-mode");
                localStorage.setItem("FinappDarkmode", "0");
                this.switchDarkModeCheck(false);
            }
            else {
                pageBody.classList.add("dark-mode")
                this.switchDarkModeCheck(true);
                localStorage.setItem("FinappDarkmode", "1");
            }
        },
        async logout() {
            console.log('logout')
            const response = await axios.post('/logout',{}, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
            });
            let res = response.data;
            if (res.status == 'success') {
                document.querySelector('body').classList.remove('modal-open');
                var elements = document.getElementsByClassName('modal-backdrop');
                elements[0].parentNode.removeChild(elements[0]);
                // document.getElementById('sidebarPanel').classList.remove("show")
                // var myModal = new bootstrap.Modal(document.getElementById('sidebarPanel'));
                // myModal.hide();
                
                localStorage.removeItem('sescode');
                //   this.$refs.notif.SnackbarShow('success', 'Logout success!');
                localStorage.removeItem('token');
                this.$router.push('/login');
            } else {
            //   this.$refs.notif.SnackbarShow('error', 'Logout failed!');
            }
        },
        reloadpage() {
            console.log('clicked')
            this.$router.go()
        }
    },
}
</script>
