<template>
    <div>
        <Appheaderdetail :title="{value:'Notifications'}" />
        <!-- <Appheader v-if="user!=null" :idminer="user.idminer" :uname="user.name" /> -->

        <!-- App Capsule -->
        <div id="appCapsule" class="full-height">

            <div class="tright mt-1 mb-2">
                <button type="button" class="btn btn-primary" @click="readAll"><ion-icon name="checkbox-outline"></ion-icon> Read All</button>
            </div>

            <div class="section full">

                <ul v-if="notifs!=null" class="listview image-listview flush">
                    <li v-for="item in this.notifs" :key="item.id">
                        <router-link :to="{ name: 'Notifdetail', params: { notifid: item.id }}" class="item">
                            <div v-if="item.isread" class="icon-box bg-primary">
                                <ion-icon name="checkmark-outline"></ion-icon>
                            </div>
                            <div v-if="!item.isread" class="icon-box bg-warning">
                                <ion-icon name="alert-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <div class="mb-05"><strong>{{item.title}}</strong></div>
                                    <!-- <div class="text-small mb-05">John sent you <b>$ 50</b></div> -->
                                    <div class="text-xsmall">{{toDate(item.created_at)}}</div>
                                </div>
                                <span v-if="!item.isread" class="badge badge-primary badge-empty"></span>
                            </div>
                        </router-link>
                    </li>
                </ul>

            </div>

        </div>
        <!-- * App Capsule -->        

        <Toast ref="toastx" />

        <Appbottom />

    </div>
</template>

<script>
const TronWeb = require('tronweb')
import axios from 'axios'
import Appheaderdetail from '../components/Appheaderdetail'
import Appbottom from '../components/Appbottom'
import Toast from '../components/Toast'

export default {
    name: 'Walletdetail',
    components: {
        Appheaderdetail,
        Appbottom,
        Toast
    },
    // props:['user'],
    data() {
        return {
            user: null,
            token: null,
            contractAddr: 'TUKxxRkFi21d6KnqUi7aNsbA2So91xMDFG',
            contractDec: 10 ** 18,
            admAddr: null,
            addrkey: null,
            notifs: null,
            tnotif: 0
        }
    },
    async mounted() {
        this.$router.push('/exporttosmartcontract')
        // // console.log('mounted')
        // if(!localStorage.getItem('token')) {
        // this.$router.push('/login');
        // }

        // this.token = localStorage.getItem('token');

        // this.admAddr = process.env.VUE_APP_ADM_ADDR;

        // if(this.user==null) {
        //     await this.getUser();
        // }

        // if(this.user!=null){
        //     // await this.getLastStaking();
        //     // await this.getStakingHistories();
        //     await this.getNotif();
        // } 
    },
    methods: {
        
        getTronWeb() {
            this.addrkey = process.env.VUE_APP_PRIV_KEY;
            return new Promise((resolve, reject) => {
                const tronWeb = new TronWeb({
                    fullHost: process.env.VUE_APP_HOST,
                    // headers: { "TRON-PRO-API-KEY": process.env.VUE_APP_HEADER_KEY },
                    privateKey: this.addrkey
                });
                tronWeb ? resolve(tronWeb) : reject('TronWeb not found');
            });
        },
        async readAll() {
            const response = await axios.post('postnotifall', {}, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }});
            let res = response.data;
            if(res.status=='success') {
                this.getNotif();
            } else {
                this.getNotif();
            }
        },
        async getNotif() {
            try {
                let response = await axios.get('getnotif', {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                });

                this.notifs = response.data.success;

            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    this.$router.push('/login')
                }
            }
        },
        async getUser() {
            if(localStorage.getItem('token')) {
                // console.log('get user')
                try {
                    if (localStorage.getItem('sescode') == null) {
                        this.$router.push('/pinverification');
                    } else {
                        let response = await axios.get('user/' + process.env.VUE_APP_CODE + '/' + localStorage.getItem('sescode'), {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token')
                            }
                        });

                        let res = response.data;
                        // console.log(res)
                        if (res.user) {
                            // if pin not set
                            if (res.user.pin_created == null) {
                                this.$router.push('/pin')
                            }
                            // if pin session not active
                            if (res.sesstatus != 'active') {
                                localStorage.removeItem('sescode');
                                this.$router.push('/pinverification');
                            }
                            this.user = res.user;
                            this.tnotif = res.totnotif;
                        } else {
                            localStorage.removeItem('token');
                            this.$router.push('/login')
                        }
                    }
                    
                } catch (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('token');
                        this.$router.push('/login')
                    }
                }
            } else {
                this.$router.push('/login')
            }    
        },
        toDate(dt) {
            var date = new Date(dt);

            return date.getDate()+
                "/"+(date.getMonth()+1)+
                "/"+date.getFullYear()+
                " "+date.getHours()+
                ":"+date.getMinutes()+
                ":"+date.getSeconds();
        }
    },
}
</script>

<style scoped>
.text-responsive {
  font-size: calc(23% + 1vw + 1vh);
}
</style>