<template>
    <div>
        <!-- <Appheader v-if="user!=null" :idminer="user.idminer" :uname="user.name" :tnotif="tnotif" :samount="samount" :botid="user.botid" /> -->
        <Appheaderdetail :title="{value:'Minted Histories'}" />
        <!-- App Capsule -->
        <div id="appCapsule">

            <!-- Wallet Card -->
            <div v-if="lockstaking" class="section wallet-card-section pt-1">
                <div class="wallet-card">
                    <div class="listed-detail mt-3">
                        <flip-countdown v-if="deadline!=null" :nowdate="nowdate" :deadline="deadline" @timeElapsed="timeElapsedHandler"></flip-countdown>
                        <h3 class="text-center mt-2">Next Mining</h3>
                    </div>
                </div>
            </div>
            <!-- Wallet Card -->

            <!-- my cards -->
        <!-- <div class="section full mt-4">
            <div class="section-heading padding">
                <h2 class="title">Staking List</h2>
            </div>
            <ul class="listview image-listview flush">
                <li class="">
                    <div href="#" class="item">
                        <div class="icon-box bg-primary">
                            <ion-icon name="checkmark-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>
                                <div class="mb-05"><strong>5 DCT</strong><span></span></div>
                                <div class="text-xsmall mb-05">Created : <b>5/3/2020 10:30 AM</b></div>
                                <div class="text-small">Next Mining : <b>5/3/2020 10:30 AM</b></div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

        </div> -->
        <!-- * my cards -->

            <!-- Transactions -->
            <div class="section mt-4 mb-5">
                <div class="section-heading">
                    <h2 class="title">Histories</h2>
                    <!-- <a href="app-transactions.html" class="link">View All</a> -->
                </div>
                <div class="transactions">
                    <!-- item -->
                    <a href="#" class="item" v-for="item in histories" :key="item.id">
                        <div class="detail">
                            <!-- <img src="assets/img/sample/brand/1.jpg" alt="img" class="image-block imaged w48"> -->
                            <div>
                                <strong>{{item.description}}</strong>
                                <p>{{toDate(item.created_at)}}</p>
                            </div>
                        </div>
                        <div class="right tright">
                            <div v-if="item.paytype=='in'" class="price text-success">+ {{parseFloat(item.amountin)}} DCT</div>
                            <div v-if="item.paytype=='out'" class="price text-danger">- {{parseFloat(item.amountout)}} DCT</div>
                        </div>
                    </a>
                    <!-- * item -->
                </div>
            </div>
            <!-- * Transactions -->

        </div>
        <!-- * App Capsule -->        

        <Appbottom />

    </div>
</template>

<script>
const TronWeb = require('tronweb')
import axios from 'axios'
// import Appheader from '../components/Appheader'
import Appheaderdetail from '../components/Appheaderdetail'
import Appbottom from '../components/Appbottom'
import FlipCountdown from 'vue2-flip-countdown'

export default {
    name: 'Staking',
    components: {
        Appheaderdetail,
        Appbottom,
        FlipCountdown
    },
    // props:['user'],
    data() {
        return {
          user: null,
        token: null,
            contractDec: 10 ** 18,
            lockstaking: null,
            deadline: null,
            nowdate: null,
            ismining: true,
            miningprogres: 10,
            addrkey: null,
            amount: 0,
            trxbalance: 0,
            tokenbalance: 0,
            // availablestake: 0,
            // tokenlocked: 0,
            maxstaking: 0,
            histories: null,
            staking: null,
            tnotif: 0,
            samount: 0
        }
    },
    async mounted() {
        this.$router.push('/exporttosmartcontract')
        // // console.log('mounted')
        // // console.log(this.$moment().format())
        // // console.log(new Date())
        // // console.log(new Date().toLocaleString('en-US',{timeZone:'Asia/Jakarta',dateStyle: 'full',timeStyle: 'full',}))
        // // console.log(new Date(new Date().toLocaleString('en-US',{timeZone:'Asia/Jakarta'})))
        // // console.log(new Date(new Date().toLocaleString('en-US',{timeZone:'Asia/Jakarta'})).getTime())
        // // console.log(new Date().getTime())
        // if(!localStorage.getItem('token')) {
        // this.$router.push('/login');
        // }

        // this.token = localStorage.getItem('token');

        // if(this.user==null) {
        //     await this.getUser();
        // }

        // if(this.user!=null){
        // // this.getbalance();
        // // // console.log('trxbalance',this.trxbalance)
        
        // // this.gettokenbalance();
        // // this.availablestake = this.tokenbalance - this.tokenlocked;
        //     await this.getLastStaking();
        //     await this.getStakingHistories();
        // } 
        
        // // else {
        // //   localStorage.removeItem('token');
        // //   this.$router.push('/login');
        // // }    
    },
    methods: {
        getTronWeb() {
            this.addrkey = process.env.VUE_APP_PRIV_KEY;
            return new Promise((resolve, reject) => {
                const tronWeb = new TronWeb({
                    fullHost: process.env.VUE_APP_HOST,
                    // headers: { "TRON-PRO-API-KEY": process.env.VUE_APP_HEADER_KEY },
                    privateKey: this.addrkey
                });
                tronWeb ? resolve(tronWeb) : reject('TronWeb not found');
            });
        },
        getbalance(){
            this.getTronWeb().then(async (tronWeb) => {
            // console.log(this.user.addr)
            var getbal = await tronWeb.trx.getBalance(this.user.addr);
            // console.log('getbal', getbal)
            this.trxbalance =  tronWeb.fromSun(getbal);
            });
        },
        gettokenbalance(){
            this.getTronWeb().then(async (tronWeb) => {
            // console.log(this.user.addr)
            var contract = await tronWeb.contract().at(process.env.VUE_APP_CONTRACT);
            var tbal = await contract.balanceOf(this.user.addr).call();
            this.tokenbalance =  tronWeb.toBigNumber(tbal)/this.contractDec;
            });
        },
        timeElapsedHandler: function () {
            this.deadline = null;
        },
        async getLastStaking() {
            // console.log('staking called')
            try {
                let response = await axios.get('laststaking', {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                });

                let res = response.data;
                // console.log(res)
                if(res.success) {
                    this.staking = res.success;
                    if (this.staking.status == 'waiting' || this.staking.status == 'running') {
                        this.lockstaking = true;
                        // this.deadline = this.staking.nextshare;
                        // this.deadline = this.$moment.tz(this.staking.nextshare + '+07:00', 'Asia/Jakarta').format();
                        var time_tz = this.$moment.tz(this.staking.nextshare, "Asia/Jakarta");
                        // console.log(time_tz.format())
                        var time_utc = time_tz.utc().format();
                        // console.log(time_utc)

                        var user_zone = this.$moment.tz.guess();
                        // console.log(user_zone)

                        this.deadline = this.$moment.tz(time_utc, user_zone).format("YYYY-MM-DD HH:mm:ss");
                        // console.log(this.deadline)
                        this.nowdate = res.nowdate;

                        // console.log(this.staking.nextshare)
                        // console.log(this.deadline)
                        // console.log(this.$moment.tz(this.deadline, 'Asia/Jakarta').format());
                    } else {
                        this.lockstaking = false;
                    }
                } else {
                    console.log(res)
                }
                
                // console.log(res)
            } catch (error) {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    this.$router.push('/login')
                }
            }
        },
        async getStakingHistories(){
            try { 
                if(this.staking) {
                    let response = await axios.get('getstakinghistories/' + this.staking.id, {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    });

                    let res = response.data;
                    // console.log(res)
                    if (res.success) {
                        this.histories = res.success;
                    } else {
                        console.log(res)
                    }
                }
                
                
                // console.log(this.histories)
            } catch (error) {
                // console.log(error)
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    this.$router.push('/login')
                }
            }
        },
        async getUser() {
            if(localStorage.getItem('token')) {
                // console.log('get user')
                try {
                    if (localStorage.getItem('sescode') == null) {
                        this.$router.push('/pinverification');
                    } else {
                        let response = await axios.get('user/' + process.env.VUE_APP_CODE + '/' + localStorage.getItem('sescode'), {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token')
                            }
                        });

                        let res = response.data;
                        // console.log(res)
                        if (res.user) {
                            // if pin not set
                            if (res.user.pin_created == null) {
                                this.$router.push('/pin')
                            }
                            // if pin session not active
                            if (res.sesstatus != 'active') {
                                localStorage.removeItem('sescode');
                                this.$router.push('/pinverification');
                            }
                            this.user = res.user;
                            this.tnotif = res.totnotif;
                            this.samount = res.samount;
                        } else {
                            localStorage.removeItem('token');
                            this.$router.push('/login')
                        }
                    }
                    
                } catch (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('token');
                        this.$router.push('/login')
                    }
                }
            } else {
                this.$router.push('/login')
            }    
        },
        toDate(dt) {
            var date = new Date(dt);

            return date.getDate()+
                "/"+(date.getMonth()+1)+
                "/"+date.getFullYear()+
                " "+date.getHours()+
                ":"+date.getMinutes()+
                ":"+date.getSeconds();
        }
    },
}
</script>