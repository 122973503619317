<template>
    <div>
        <Appheaderdetail :title="{value:'Transaction Detail'}" />
        <!-- <Appheader v-if="user!=null" :idminer="user.idminer" :uname="user.name" /> -->

        <!-- App Capsule -->
        <div id="appCapsule" class="full-height">

            <div class="section">

                <div class="listed-detail mt-3">
                    <div class="icon-wrapper">
                        <div class="iconbox">
                            <ion-icon name="analytics-outline"></ion-icon>
                        </div>
                    </div>
                    <h3 v-if="txdetail!=null" class="text-center text-break mt-2">{{txdetail.hash}}</h3>
                </div>

                <ul v-if="txdetail!=null" class="listview simple-listview no-space mt-3 ps-1 pe-1">
                    <li>
                        <span>Result</span>
                        <strong>{{txdetail.contractRet}}</strong>
                    </li>
                    <li>
                        <span>From</span>
                        <strong>{{txdetail.ownerAddress}}</strong>
                    </li>
                    <li>
                        <span>To</span>
                        <strong>{{txdetail.tokenTransferInfo.to_address}}</strong>
                    </li>
                    <li>
                        <span>Date</span>
                        <strong>{{toDate(txdetail.timestamp)}}</strong>
                    </li>
                    <li>
                        <span>Amount</span>
                        <strong>{{this.$math.divide(this.$math.bignumber(txdetail.tokenTransferInfo.amount_str), this.$math.bignumber(this.contractDec))}}</strong>
                    </li>
                </ul>

            </div>

        </div>
        <!-- * App Capsule -->        

        <Toast ref="toastx" />

        <Appbottom />

    </div>
</template>

<script>
const TronWeb = require('tronweb')
import axios from 'axios'
import Appheaderdetail from '../components/Appheaderdetail'
import Appbottom from '../components/Appbottom'
import Toast from '../components/Toast'

export default {
    name: 'Walletdetail',
    components: {
        Appheaderdetail,
        Appbottom,
        Toast
    },
    // props:['user'],
    data() {
        return {
            user: null,
            token: null,
            contractAddr: 'TUKxxRkFi21d6KnqUi7aNsbA2So91xMDFG',
            contractDec: 10 ** 18,
            admAddr: null,
            addrkey: null,
            histories: null,
            txid: null,
            txdetail: null,
            tnotif: 0
        }
    },
    async mounted() {
        this.$router.push('/exporttosmartcontract')
        // // console.log('mounted')
        // if(!localStorage.getItem('token')) {
        // this.$router.push('/login');
        // }

        // this.token = localStorage.getItem('token');

        // this.admAddr = process.env.VUE_APP_ADM_ADDR;

        // this.txid = this.$route.params.txid;

        // if(this.user==null) {
        //     await this.getUser();
        // }

        // if(this.user!=null){
        //     // await this.getLastStaking();
        //     // await this.getStakingHistories();
        //     await this.getTxdetail();
        // } 
    },
    methods: {
        
        getTronWeb() {
            this.addrkey = process.env.VUE_APP_PRIV_KEY;
            return new Promise((resolve, reject) => {
                const tronWeb = new TronWeb({
                    fullHost: process.env.VUE_APP_HOST,
                    // headers: { "TRON-PRO-API-KEY": process.env.VUE_APP_HEADER_KEY },
                    privateKey: this.addrkey
                });
                tronWeb ? resolve(tronWeb) : reject('TronWeb not found');
            });
        },
        async getTxdetail() {
            try {
                let response = await axios.get('https://apilist.tronscan.org/api/transaction-info?hash='+ this.txid);

                this.txdetail = response.data;

            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    this.$router.push('/login')
                }
            }
        },
        async getUser() {
            if(localStorage.getItem('token')) {
                // console.log('get user')
                try {
                    if (localStorage.getItem('sescode') == null) {
                        this.$router.push('/pinverification');
                    } else {
                        let response = await axios.get('user/' + process.env.VUE_APP_CODE + '/' + localStorage.getItem('sescode'), {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token')
                            }
                        });

                        let res = response.data;
                        // console.log(res)
                        if (res.user) {
                            // if pin not set
                            if (res.user.pin_created == null) {
                                this.$router.push('/pin')
                            }
                            // if pin session not active
                            if (res.sesstatus != 'active') {
                                localStorage.removeItem('sescode');
                                this.$router.push('/pinverification');
                            }
                            this.user = res.user;
                            this.tnotif = res.totnotif;
                        } else {
                            localStorage.removeItem('token');
                            this.$router.push('/login')
                        }
                    }
                    
                } catch (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('token');
                        this.$router.push('/login')
                    }
                }
            } else {
                this.$router.push('/login')
            }    
        },
        toDate(dt) {
            var date = new Date(dt);

            return date.getDate()+
                "/"+(date.getMonth()+1)+
                "/"+date.getFullYear()+
                " "+date.getHours()+
                ":"+date.getMinutes()+
                ":"+date.getSeconds();
        }
    },
}
</script>

<style scoped>
.text-responsive {
  font-size: calc(23% + 1vw + 1vh);
}
</style>