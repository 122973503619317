<template>
    <div>
        <Appheader v-if="user!=null" :idminer="user.idminer" :uname="user.name" :tnotif="tnotif" :samount="samount" :botid="user.botid" />

        <!-- App Capsule -->
        <div id="appCapsule">

            <!-- Wallet Card -->
            <div class="section wallet-card-section pt-1">
                <div class="wallet-card">
                    <div class="listed-detail text-center mt-3">
                        <h2>Wallet Address</h2>
                        <qrcode v-if="user" :value=user.addr :options="{ width: 200 }"></qrcode>
                        <p class="text-responsive" v-if="user">{{user.addr}}</p>
                        <button v-if="user" type="button" class="btn btn-primary btn-block btn-lg"
                            v-clipboard:copy="user.addr"
                            v-clipboard:success="onCopy"
                            v-clipboard:error="onError"
                            data-bs-dismiss="modal">
                            Copy Address</button>
                    </div>
                    <!-- * Wallet Footer -->
                </div>
            </div>
            <!-- Wallet Card -->

            <!-- Extra Header -->
            <div class="extraHeader pe-0 ps-0">
                
            </div>
            <!-- * Extra Header -->

            <!-- Transactions -->
            <div class="section mt-4 mb-5">
                <div class="section-heading">
                    <h2 class="title">Wallet Histories</h2>
                    <!-- <a href="app-transactions.html" class="link">View All</a> -->
                </div>
                <ul class="nav nav-tabs lined" role="tablist">
                    <li class="nav-item" @click="dcttabclick()">
                        <a class="nav-link active" data-bs-toggle="tab" href="#dcttab" role="tab">
                            DCT
                        </a>
                    </li>
                    <li class="nav-item" @click="trxtabclick()">
                        <a class="nav-link" data-bs-toggle="tab" href="#trxtab" role="tab">
                            TRX
                        </a>
                    </li>
                </ul>
                <div class="transactions">
                    <div class="tab-pane fade show active" id="dcttab" role="tabpanel">
                        
                        <!-- item -->
                        <div v-for="item in this.histories" :key="item.hash" class="mt-1">
                            <router-link :to="{ name: 'Walletdetail', params: { txid: item.hash }}" class="item">
                                <div class="detail">
                                    <!-- <img v-if="item.from==user.addr" src="upload.png" alt="img" class="image-block imaged w32">
                                    <img v-if="item.to==user.addr" src="download.png" alt="img" class="image-block imaged w32"> -->
                                    <span class="image-block imaged w48">
                                        <ion-icon v-if="item.from==user.addr" name="arrow-up-circle-outline" class="text-danger" style="font-size: 40px;"></ion-icon>
                                        <ion-icon v-if="item.to==user.addr" name="arrow-down-circle-outline" class="text-success" style="font-size: 40px;"></ion-icon>
                                    </span>
                                    
                                    <div>
                                        <strong v-if="item.to==user.addr">Receive</strong>
                                        <strong v-if="item.from==user.addr && item.to==admAddr">Staking</strong>
                                        <strong v-if="item.from==user.addr && item.to!=admAddr">Transfer</strong>
                                        <p>{{toDate(item.block_timestamp)}}</p>
                                    </div>
                                </div>
                                <div class="right tright">
                                    <div v-if="item.to==user.addr" class="price text-success">+ {{parseFloat(item.amount/contractDec)}} DCT</div>
                                    <div v-if="item.from==user.addr" class="price text-danger">- {{parseFloat(item.amount/contractDec)}} DCT</div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="trxtab" role="tabpanel">
                        <!-- item -->
                        <div v-for="itemtrx in this.trxhistories" :key="itemtrx.transactionHash" class="mt-1">
                            <router-link :to="{ name: 'Walletdetail', params: { txid: itemtrx.transactionHash }}" class="item">
                                <div class="detail">
                                    <!-- <img v-if="itemtrx.from==user.addr" src="upload.png" alt="img" class="image-block imaged w32">
                                    <img v-if="itemtrx.to==user.addr" src="download.png" alt="img" class="image-block imaged w32"> -->
                                    <span class="image-block imaged w48">
                                        <ion-icon v-if="itemtrx.transferFromAddress==user.addr" name="arrow-up-circle-outline" class="text-danger" style="font-size: 40px;"></ion-icon>
                                        <ion-icon v-if="itemtrx.transferToAddress==user.addr" name="arrow-down-circle-outline" class="text-success" style="font-size: 40px;"></ion-icon>
                                    </span>
                                    
                                    <div>
                                        <strong v-if="itemtrx.transferToAddress==user.addr">Receive</strong>
                                        <strong v-if="itemtrx.transferFromAddress==user.addr">Transfer</strong>
                                        <p>{{toDate(itemtrx.timestamp)}}</p>
                                    </div>
                                </div>
                                <div class="right tright">
                                    <div v-if="itemtrx.transferToAddress==user.addr" class="price text-success">+ {{parseFloat(itemtrx.amount/(10 ** itemtrx.tokenInfo.tokenDecimal))}} {{itemtrx.tokenInfo.tokenAbbr}}</div>
                                    <div v-if="itemtrx.transferFromAddress==user.addr" class="price text-danger">- {{parseFloat(itemtrx.amount/(10 ** itemtrx.tokenInfo.tokenDecimal))}} {{itemtrx.tokenInfo.tokenAbbr}}</div>
                                </div>
                            </router-link>
                        </div>
                    
                     </div>
                    <!-- * item -->
                </div>
                
            </div>
            <!-- * Transactions -->

        </div>
        <!-- * App Capsule -->        

        <Toast ref="toastx" />

        <Appbottom />

    </div>
</template>

<script>
const TronWeb = require('tronweb')
import axios from 'axios'
import Appheader from '../components/Appheader'
import Appbottom from '../components/Appbottom'
import Toast from '../components/Toast'

export default {
    name: 'Wallet',
    components: {
        Appheader,
        Appbottom,
        Toast
    },
    // props:['user'],
    data() {
        return {
            user: null,
            token: null,
            contractAddr: 'TUKxxRkFi21d6KnqUi7aNsbA2So91xMDFG',
            contractDec: 10 ** 18,
            admAddr: null,
            addrkey: null,
            histories: null,
            trxhistories: null,
            tnotif: 0,
            samount: 0
        }
    },
    async mounted() {
        this.$router.push('/exporttosmartcontract')
        // // console.log('mounted')
        // if(!localStorage.getItem('token')) {
        // this.$router.push('/login');
        // }

        // this.token = localStorage.getItem('token');

        // this.admAddr = process.env.VUE_APP_ADM_ADDR;

        // if(this.user==null) {
        //     await this.getUser();
        // }

        // if(this.user!=null){
        //     // await this.getLastStaking();
        //     // await this.getStakingHistories();
        //     await this.getHistories();
        // } 
    },
    methods: {
        onCopy: function (e) {
            this.$refs.toastx.toastbox('toast-msg-primary','Address copied', 3000)
            console.log(e.text)
            // alert('You just copied: ' + e.text)
        },
        onError: function (e) {
            this.$refs.toastx.toastbox('toast-msg-danger', 'Failed to copy address', 3000)
            // alert('Failed to copy texts')
            console.log(e)
        },
        async dcttabclick() {
            this.trxhistories = [];
            await this.getHistories();
        },
        async trxtabclick() {
            // console.log('trx')
            this.histories = [];
            await this.getTrxHistories();
        },
        getTronWeb() {
            this.addrkey = process.env.VUE_APP_PRIV_KEY;
            return new Promise((resolve, reject) => {
                const tronWeb = new TronWeb({
                    fullHost: process.env.VUE_APP_HOST,
                    // headers: { "TRON-PRO-API-KEY": process.env.VUE_APP_HEADER_KEY },
                    privateKey: this.addrkey
                });
                tronWeb ? resolve(tronWeb) : reject('TronWeb not found');
            });
        },
        getbalance(){
            this.getTronWeb().then(async (tronWeb) => {
            // console.log(this.user.addr)
            var getbal = await tronWeb.trx.getBalance(this.user.addr);
            // console.log('getbal', getbal)
            this.trxbalance =  tronWeb.fromSun(getbal);
            });
        },
        gettokenbalance(){
            this.getTronWeb().then(async (tronWeb) => {
            // console.log(this.user.addr)
            var contract = await tronWeb.contract().at(process.env.VUE_APP_CONTRACT);
            var tbal = await contract.balanceOf(this.user.addr).call();
            this.tokenbalance =  tronWeb.toBigNumber(tbal)/this.contractDec;
            });
        },
        async getHistories() {
            try {
                let uaddr = this.user.addr;
                let response = await axios.get('https://apilist.tronscan.org/api/transfer/trc20?sort=-timestamp&limit=10&start=0&address='+ uaddr +'&direction=0&db_version=1&trc20Id='+ this.contractAddr);

                this.histories = response.data.data;
                // console.log(this.histories)
                // res.forEach((value) => {
                //     // console.log(value);
                //     // console.log(index);
                //     if(value.from==uaddr) {
                //         console.log(value)
                //     } else {
                //         console.log(value)
                //     }
                // });

                // this.staking = res.success;
                // if(this.staking.status=='waiting' || this.staking.status=='running') {
                //     this.lockstaking = true;
                //     this.deadline = this.staking.nextshare;
                // } else {
                //     this.lockstaking = false;
                // }
                // // console.log(res)
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    this.$router.push('/login')
                }
            }
        },
        async getTrxHistories() {
            try {
                let uaddr = this.user.addr;
                let response = await axios.get('https://apilist.tronscan.org/api/transfer?sort=-timestamp&count=true&limit=20&start=0&token=_&address='+ uaddr);

                this.trxhistories = response.data.data;

            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    this.$router.push('/login')
                }
            }
        },
        async getUser() {
            if(localStorage.getItem('token')) {
                // console.log('get user')
                try {
                    if (localStorage.getItem('sescode') == null) {
                        this.$router.push('/pinverification');
                    } else {
                        let response = await axios.get('user/' + process.env.VUE_APP_CODE + '/' + localStorage.getItem('sescode'), {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token')
                            }
                        });

                        let res = response.data;
                        // console.log(res)
                        if (res.user) {
                            // if pin not set
                            if (res.user.pin_created == null) {
                                this.$router.push('/pin')
                            }
                            // if pin session not active
                            if (res.sesstatus != 'active') {
                                localStorage.removeItem('sescode');
                                this.$router.push('/pinverification');
                            }
                            this.user = res.user;
                            this.tnotif = res.totnotif;
                            this.samount = res.samount;
                        } else {
                            localStorage.removeItem('token');
                            this.$router.push('/login')
                        }
                    }
                    
                } catch (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('token');
                        this.$router.push('/login')
                    }
                }
            } else {
                this.$router.push('/login')
            }    
        },
        toDate(dt) {
            var date = new Date(dt);

            return date.getDate()+
                "/"+(date.getMonth()+1)+
                "/"+date.getFullYear()+
                " "+date.getHours()+
                ":"+date.getMinutes()+
                ":"+date.getSeconds();
        }
    },
}
</script>

<style scoped>
.text-responsive {
  font-size: calc(23% + 1vw + 1vh);
}
</style>