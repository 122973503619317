<template>
    <div>
        <!-- App Bottom Menu -->
        <div class="appBottomMenu">
            <router-link to="/home" class="item">
                <div class="col">
                    <ion-icon name="pie-chart-outline"></ion-icon>
                    <strong>Overview</strong>
                </div>
            </router-link>
            <!-- <router-link to="/staking" class="item">
                <div class="col">
                    <ion-icon name="document-text-outline"></ion-icon>
                    <strong>Staking</strong>
                </div>
            </router-link> -->
            <router-link to="/wallet" class="item">
                <div class="col">
                    <ion-icon name="wallet-outline"></ion-icon>
                    <strong>Wallet</strong>
                </div>
            </router-link>
            <router-link to="/reward" class="item">
                <div class="col">
                    <ion-icon name="people-outline"></ion-icon>
                    <strong>Reward</strong>
                </div>
            </router-link>
            <router-link to="/package" class="item">
                <div class="col">
                    <ion-icon name="apps-outline"></ion-icon>
                    <strong>Package</strong>
                </div>
            </router-link>
            <!-- <a to="#" class="item">
                <div class="col">
                    <ion-icon name="card-outline"></ion-icon>
                    <strong>My Cards</strong>
                </div>
            </a>
            <a to="#" class="item">
                <div class="col">
                    <ion-icon name="settings-outline"></ion-icon>
                    <strong>Settings</strong>
                </div>
            </a> -->
        </div>
        <!-- * App Bottom Menu -->
    </div>
</template>

<script>
    export default {
        name: "Appbottom"
    }
</script>