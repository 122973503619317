<template>
    <div>
        <!-- <Appheader v-if="user!=null" :idminer="user.idminer" :uname="user.name" :tnotif="tnotif" :samount="samount" :botid="user.botid" /> -->
        <Appheaderdetail :title="{value:'Burned Histories'}" />
        <!-- App Capsule -->
        <div id="appCapsule" class="full-height">

            <!-- Transactions -->
            <div class="section mt-4 mb-5">
                <div class="section-heading">
                    <h2 class="title">Histories</h2>
                </div>
                <div class="transaction">
                    <!-- item -->
                    <div class="item" v-for="item in histories" :key="item.created_at">

                        <ul class="listview">
                            <li>
                                <strong>Burn Date</strong>
                                <span class="text-success">{{toDate(item.created_at)}}</span>
                            </li>
                            <li>
                                <strong>Burn Hash</strong>
                                <span>{{parseFloat(item.txburn)}} DCT</span>
                            </li>
                        </ul>
                        
                    </div>
                    <!-- * item -->
                </div>

            </div>
            <!-- * Transactions -->

        </div>
        <!-- * App Capsule -->        

        <Appbottom />

    </div>
</template>

<script>
const TronWeb = require('tronweb')
import axios from 'axios'
// import Appheader from '../components/Appheader'
import Appheaderdetail from '../components/Appheaderdetail'
import Appbottom from '../components/Appbottom'

export default {
    name: 'Stakinghistories',
    components: {
        Appheaderdetail,
        Appbottom
    },
    // props:['user'],
    data() {
        return {
          user: null,
        token: null,
            contractDec: 10 ** 18,
            lockstaking: null,
            deadline: null,
            ismining: true,
            miningprogres: 10,
            addrkey: null,
            amount: 0,
            trxbalance: 0,
            tokenbalance: 0,
            // availablestake: 0,
            // tokenlocked: 0,
            maxstaking: 0,
            histories: null,
            staking: null,
            tnotif: 0,
            samount: 0
        }
    },
    async mounted() {
        this.$router.push('/exporttosmartcontract')
        // // console.log('mounted')
        // if(!localStorage.getItem('token')) {
        // this.$router.push('/login');
        // }

        // this.token = localStorage.getItem('token');

        // if(this.user==null) {
        //     await this.getUser();
        // }

        // if(this.user!=null){
        // // this.getbalance();
        // // // console.log('trxbalance',this.trxbalance)
        
        // // this.gettokenbalance();
        // // this.availablestake = this.tokenbalance - this.tokenlocked;
        //     // await this.getLastStaking();
        //     await this.getBurnedHistories();
        // } 
        
        // // else {
        // //   localStorage.removeItem('token');
        // //   this.$router.push('/login');
        // // }    
    },
    methods: {
        getTronWeb() {
            this.addrkey = process.env.VUE_APP_PRIV_KEY;
            return new Promise((resolve, reject) => {
                const tronWeb = new TronWeb({
                    fullHost: process.env.VUE_APP_HOST,
                    // headers: { "TRON-PRO-API-KEY": process.env.VUE_APP_HEADER_KEY },
                    privateKey: this.addrkey
                });
                tronWeb ? resolve(tronWeb) : reject('TronWeb not found');
            });
        },
        getbalance(){
            this.getTronWeb().then(async (tronWeb) => {
            // console.log(this.user.addr)
            var getbal = await tronWeb.trx.getBalance(this.user.addr);
            // console.log('getbal', getbal)
            this.trxbalance =  tronWeb.fromSun(getbal);
            });
        },
        gettokenbalance(){
            this.getTronWeb().then(async (tronWeb) => {
            // console.log(this.user.addr)
            var contract = await tronWeb.contract().at(process.env.VUE_APP_CONTRACT);
            var tbal = await contract.balanceOf(this.user.addr).call();
            this.tokenbalance =  tronWeb.toBigNumber(tbal)/this.contractDec;
            });
        },
        timeElapsedHandler: function () {
            this.deadline = null;
        },
        async getBurnedHistories(){
            try {
                let response = await axios.get('getburnhistories', {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                });

                let res = response.data;
                // console.log(res)
                this.histories = res.success;
                
                // console.log(this.histories)
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    this.$router.push('/login')
                }
            }
        },
        async getLastStaking() {
            // console.log('staking called')
            try {
                let response = await axios.get('laststaking', {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                });

                let res = response.data;
                // console.log(res)
                this.staking = res.success;
                if(this.staking.status=='waiting' || this.staking.status=='running') {
                    this.lockstaking = true;
                    this.deadline = this.staking.nextshare;
                } else {
                    this.lockstaking = false;
                }
                // console.log(res)
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    this.$router.push('/login')
                }
            }
        },
        async getStakingHistories(){
            try {
                let response = await axios.get('getstakinghistories/' + this.staking.id, {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                });

                let res = response.data;
                // console.log(res)
                this.histories = res.success;
                
                // console.log(this.histories)
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    this.$router.push('/login')
                }
            }
        },
        async getUser() {
            if(localStorage.getItem('token')) {
                // console.log('get user')
                try {
                    if (localStorage.getItem('sescode') == null) {
                        this.$router.push('/pinverification');
                    } else {
                        let response = await axios.get('user/' + process.env.VUE_APP_CODE + '/' + localStorage.getItem('sescode'), {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token')
                            }
                        });

                        let res = response.data;
                        // console.log(res)
                        if (res.user) {
                            // if pin not set
                            if (res.user.pin_created == null) {
                                this.$router.push('/pin')
                            }
                            // if pin session not active
                            if (res.sesstatus != 'active') {
                                localStorage.removeItem('sescode');
                                this.$router.push('/pinverification');
                            }
                            this.user = res.user;
                            this.tnotif = res.totnotif;
                            this.samount = res.samount;
                        } else {
                            localStorage.removeItem('token');
                            this.$router.push('/login')
                        }
                    }
                    
                } catch (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('token');
                        this.$router.push('/login')
                    }
                }
            } else {
                this.$router.push('/login')
            }    
        },
        toDate(dt) {
            var date = new Date(dt);

            return date.getDate()+
                "/"+(date.getMonth()+1)+
                "/"+date.getFullYear()+
                " "+date.getHours()+
                ":"+date.getMinutes()+
                ":"+date.getSeconds();
        }
    },
}
</script>