<template>
    <div>
        <div class="loader" v-if="loading"></div>
        <Appheader v-if="user!=null" :idminer="user.idminer" :uname="user.name" :tnotif="tnotif" :samount="samount" :botid="user.botid" />

        <!-- App Capsule -->
        <div id="appCapsule">

            <!-- Wallet Card -->
            <div class="section wallet-card-section pt-1">
                <div class="wallet-card">
                    <div class="listed-detail text-center mt-3" v-if="user != null && user.exportstatus=='asking'">
                        <h2 class="mt-5">Get Export Key</h2>
                        <p v-if="errors.length">
                        <ul>
                            <li class="text-danger">{{ errors }}</li>
                        </ul>
                        </p>
                        <div>
                            <p>We migrated tokens from version 1 to version 2. We also changed the staking system to a full smart contract. To be able to use our latest system, please export your data to the new smart contract staking. Please press the button below to get the migration process queue. Queuing the migration process will take 1 to 3 hours.</p>
                            <!-- <p>Address : {{ user.addr }}</p> -->
                            <form @submit.prevent="generateuserkey()" v-if="user.showkeystatus == '-'">
                                <p class="text-danger">Note : Make sure your address has a minimum balance of 200 TRX</p>
                                <label for="">Your current address</label>
                                <input type="text" class="form-control text-center" v-model="user.addr" readonly>
                                <p>TRX Balance : {{ trxbalance }}</p>
                                <label for="">Your staker address</label>
                                <input type="text" class="form-control text-center" v-model="user.staker" placeholder="New Staker Address">
                                <p class="text-danger">Note : This address will be used as your staker on miner APP. Please make sure you have the private Key!</p>
                                {{ user.uemail }}
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control text-center" v-model="user.code" aria-label="code">
                                    <button class="btn" :class="[isPrimary ? 'btn-primary' : 'btn-success']" id="idcodesend" :disabled="codesenddisabled" @click="sendcode">{{ codesendtext }}</button>
                                </div>
                                <button type="submit" name="btnmigrate" id="btnmigrate" class="btn btn-primary btn-block mt-1">Get Export Key Now</button>
                            </form>
                            <div v-if="user.showkeystatus == 'generated'">
                                <p class="text-success">Note : Export key has been sent to your email!</p>
                            </div>
                        </div>
                        
                    </div>
                    <div class="listed-detail text-center mt-3" v-if="user != null && user.exportstatus=='queue'">
                        <h2 class="mt-5">You are in export queue</h2>
                        <p v-if="errors.length">
                        <ul>
                            <li class="text-danger">{{ errors }}</li>
                        </ul>
                        </p>
                        <h3>Export Status : {{ user.exportstatus }}</h3>
                        <h3 v-if="minerqueue!=null">Queue No : {{ minerqueue.queue_no }}</h3>
                        <h4 v-if="lastqueue != null">Last Export No : {{ lastqueue.queue_no }}</h4>
                        <h4 v-if="lastqueue == null">Last Export No : 0</h4>
                        <h4 v-if="minerqueue != null">Export Tx ID : </h4>
                        <textarea name="addrkexporttxidey" class="form-control mb-3" readonly v-model="minerqueue.exporttxid"></textarea>
                        <h4 v-if="minerqueue != null">Export Tx Status : {{ minerqueue.exporttxstatus }}</h4>
                    </div>
                    <div class="listed-detail text-center mt-3" v-if="user != null && user.exportstatus=='exported'">
                        <h2 class="mt-5">Congratulations,</h2>
                        <h4 >Your data has been successfully exported to our smart contract.</h4>
                        <h4 >To use the latest application, please visit the following address:</h4>
                        <a href="https://staking.degreecrypto.com" class="btn btn-link">https://staking.degreecrypto.com</a>
                        <br>
                        <!-- <p>Address : {{ user.addr }}</p> -->
                        <input type="text" class="form-control text-center" v-model="user.addr" readonly>
                        <br>
                        <!-- <div v-if="user.showkeystatus=='-'">
                            <h4 >To be able to see your key address, please request using the following button:</h4>
                            
                            <button class="btn btn-primary mb-3" @click="generateuserkey">Request Key Address</button>
                        
                            <br>
                            <h4 class="text-danger">Note: the address key will only be displayed ONE TIME. Make sure you copy the key shown. For the security of your assets, KEEP YOUR KEYS SECRET</h4>
                        </div> -->

                        <!-- <div v-if="user.showkeycode!=null && user.showkeystatus=='generated'">
                            <h4>Please check your email inbox or the spam folder.</h4>
                            <h4>If you do not receive the email, please click the following button to resend the code:</h4>
                            <button class="btn btn-primary mb-3" id="idotpsend" :disabled="otpsenddisabled" @click="resendkey">{{ otpsendtext }}</button>
                        </div> -->

                        <h4 >Thank You</h4>
                        
                    </div>
                    <!-- * Wallet Footer -->
                </div>
                <div class="wallet-card mt-3" v-if="wdamountreward > 0">
                    <div class="text-center mt-3">
                        <h3 class="text-danger">NOTE : </h3>
                        <h4>You have unclaimed rewards!</h4>
                        <h4>Available to Claim : {{ wdamountreward }}</h4>
                        <a href="#" class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#withdrawActionSheetReward" @click="errors = ''">Claim Reward</a>
                    </div>
                </div>
                <div class="wallet-card mt-3" v-if="(tokenbalance > 0 || trxbalance > 0)">
                    <div class="text-center mt-3">
                        <form @submit.prevent="doWithdraw()">
                            <h3 class="text-danger">NOTE : </h3>
                            <h4>You have balance on your address!</h4>
                            <h4>TRX Balance : {{ trxbalance }}</h4>
                            <h4>Old DCT Balance : {{ tokenbalance }}</h4>
                            <h4 v-if="user.staker!=null">Transfer destination : {{ user.staker }}</h4>
                            <div v-if="user.staker==null">
                                <input type="text" class="form-control text-center" v-model="trfaddr" placeholder="Address destination">
                                <p class="text-danger">Note : Please make sure you have the private Key!</p>
                            </div>                            
                            <div class="input-group mb-3">
                                <input type="text" class="form-control text-center" v-model="user.codewd" aria-label="code">
                                <button class="btn" :class="[isPrimary ? 'btn-primary' : 'btn-success']" id="idcodesend" :disabled="codesenddisabled" @click="sendcode">{{ codesendtext }}</button>
                            </div>
                            <button type="submit" name="btnwd" id="btnwd" class="btn btn-primary btn-block mt-1">Withdraw Now</button>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Wallet Card -->

            <!-- Extra Header -->
            <div class="extraHeader pe-0 ps-0">
                
            </div>
            <!-- * Extra Header -->

        </div>
        <!-- * App Capsule -->        
        <div class="modal fade action-sheet" id="withdrawActionSheetReward" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Withdraw Staking Reward</h5>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content">
                            <form @submit.prevent="doClaimReward()">
                                <p v-if="errors.length">
                                    <ul>
                                        <li class="text-danger">{{ errors }}</li>
                                    </ul>
                                </p>
                                <div class="form-group basic">
                                    <label class="label">Enter Amount</label>
                                    <div class="input-group mb-2">
                                        <input type="number" class="form-control" placeholder="Enter an amount" v-model="wdamountreward" readonly disabled>
                                    </div>
                                </div>
                                <div class="form-group basic">
                                    <p class="text-danger">Claim reward will be taxed at 1.1% <span v-if="wdamountreward>0">: {{fround((wdamountreward*1.1)/100)}}</span></p>
                                    <!-- <p class="text-danger">Claim reward will be taxed at 1.1% <span v-if="wdamountreward>0">: {{((wdamountreward*1.1)/100).toFixed(6)}}</span></p> -->
                                </div>

                                <div class="form-group basic">
                                    <label class="label">PIN</label>
                                    <input type="password" class="form-control" placeholder="Enter PIN" v-model="pinreward">
                                </div>
                                <div class="form-group basic">
                                    <button type="submit" class="btn btn-primary btn-block btn-lg">Claim</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Toast ref="toastx" />

        <Appbottom />

    </div>
</template>

<script>
const TronWeb = require('tronweb')
import axios from 'axios'
import Appheader from '../components/Appheader'
import Appbottom from '../components/Appbottom'
import Toast from '../components/Toast'

export default {
    name: 'Exportsc',
    components: {
        Appheader,
        Appbottom,
        Toast
    },
    // props:['user'],
    data() {
        return {
            errors: '',
            user: null,
            token: null,
            contractDec: 10 ** 18,
            // queue: null,
            lastqueue: null,
            minerqueue: null,
            trxbalance: null,
            tokenbalance: null,
            wdamountreward: 0,
            pinreward: null,
            loading: true,
            countDownOTP: 10,
            otpsend: null,
            otpsenddisabled: false,
            otpsendtext: 'Re Send Email',
            countDownCode: 10,
            codesend: null,
            codesenddisabled: false,
            codesendtext: 'Send Code',
            isPrimary: true,
            trfaddr: null,
        }
    },
    async mounted() {
        this.loading = false;
        // remove background
        document.querySelector('body').classList.remove('modal-open');
        var elements = document.getElementsByClassName('modal-backdrop');
        // console.log(elements.length)
        if (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
        

        // console.log('mounted')
        if(!localStorage.getItem('token')) {
        this.$router.push('/login');
        }

        this.token = localStorage.getItem('token');

        this.admAddr = process.env.VUE_APP_ADM_ADDR;

        if(this.user==null) {
            // await this.getPinSession();
            await this.getUser();
            await this.getLastQueueNo();
            await this.getMinerQueue();
            await this.getLastStaking();
        }

    },
    methods: {
        onCopy: function (e) {
            this.$refs.toastx.toastbox('toast-msg-primary','Address copied', 3000)
            console.log(e.text)
            // alert('You just copied: ' + e.text)
        },
        onError: function (e) {
            this.$refs.toastx.toastbox('toast-msg-danger', 'Failed to copy address', 3000)
            // alert('Failed to copy texts')
            console.log(e)
        },
        getTronWeb() {
            this.addrkey = process.env.VUE_APP_PRIV_KEY;
            return new Promise((resolve, reject) => {
                const tronWeb = new TronWeb({
                    fullHost: process.env.VUE_APP_HOST,
                    // headers: { "TRON-PRO-API-KEY": process.env.VUE_APP_HEADER_KEY },
                    privateKey: this.addrkey
                });
                tronWeb ? resolve(tronWeb) : reject('TronWeb not found');
            });
        },
        countDownTimer() {
            if (this.countDownOTP > 0) {
                setTimeout(() => {
                    this.countDownOTP -= 1
                    // console.log(this.countDownOTP)
                    this.otpsendtext = this.countDownOTP + "s ";
                    this.countDownTimer()
                }, 1000)
            } else {
                this.otpsenddisabled = false;
                this.otpsendtext = "Re Send Email";
            }
        },
        countDownTimerCode() {
            if (this.countDownCode > 0) {
                setTimeout(() => {
                    this.countDownCode -= 1
                    // console.log(this.countDownCode)
                    this.codesendtext = this.countDownCode + "s ";
                    this.countDownTimerCode()
                }, 1000)
            } else {
                this.codesenddisabled = false;
                this.codesendtext = "Send Code";
                this.isPrimary = true;
            }
        },
        async sendcode() {
            try {
                this.loading = true
                this.codesenddisabled = true;
                this.countDownCode = 60;
                this.isPrimary = false;
                const response = await axios.post('/requesttoken', {}, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                });
                let res = response.data;
                if (res.status) {
                    this.$refs.toastx.toastbox('toast-msg-primary', res.message, 3000)
                } else {
                    this.codesenddisabled = false;
                    this.$refs.toastx.toastbox('toast-msg-danger', 'Send code failed', 3000)
                }
                await this.countDownTimerCode();
                this.loading = false
            } catch (error) {
                this.codesenddisabled = false;
                this.isPrimary = true;
                this.loading = false
                this.$refs.toastx.toastbox('toast-msg-danger', 'Send code failed', 3000)
                console.log(error);
            }
        },
        async resendkey() {
            try {
                this.loading = true
                this.otpsenddisabled = true;
                this.countDownOTP = 60;
                const response = await axios.post('/resendkey', {}, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                });
                let res = response.data;
                if (res.status == 'success') {
                    this.minerqueue = res.data;
                    await this.getUser();
                    this.$confirm(
                        {
                            title: 'Request Result',
                            message: 'Key generated successfully! You will be logged out after 5 seconds',
                            button: {
                                yes: 'OK',
                            }
                        }
                    )
                    await setTimeout(() => {
                        console.log('')
                    }, 5000)
                    localStorage.removeItem('token');
                    this.$router.push('/login')

                    this.$refs.toastx.toastbox('toast-msg-primary', res.status, 3000)
                } else {
                    this.$refs.toastx.toastbox('toast-msg-danger', res.error, 3000)
                }
                await this.countDownTimer();
                this.loading = false
            } catch (error) {
                this.loading = false
                this.$refs.toastx.toastbox('toast-msg-danger', 'Request failed!', 3000)
                console.log(error);
            }
        },
        async generateuserkey() {
            try {
                this.loading = true
                const response = await axios.post('/generateuserkey', {
                    code: this.user.code,
                    staker: this.user.staker
                }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                });
                let res = response.data;
                if (res.status == 'success') {
                    this.minerqueue = res.data;
                    await this.getUser();
                    this.$confirm(
                        {
                            title: 'Request Result',
                            message: 'Key generated successfully! Please check your email.',
                            button: {
                                yes: 'OK',
                            }
                        }
                    )
                    // await this.sleep(5000)
                    // localStorage.removeItem('token');
                    // this.$router.push('/login')

                    this.$refs.toastx.toastbox('toast-msg-primary', res.status, 3000)
                } else {
                    this.$refs.toastx.toastbox('toast-msg-danger', res.error, 3000)
                }
                this.loading = false
            } catch (error) {
                this.loading = false
                this.$refs.toastx.toastbox('toast-msg-danger', 'Request failed!', 3000)
                console.log(error);
            }
        },
        async doWithdraw() {
            try {
                this.loading = true
                var postdata = {}
                if(this.user.staker!=null) {
                    postdata = {
                        code: this.user.codewd
                    }
                } else {
                    postdata = {
                        code: this.user.codewd,
                        trfaddr: this.trfaddr
                    }
                }
                const response = await axios.post('/transferbalance', postdata, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                });
                let res = response.data;
                if (res.status == 'success') {
                    this.minerqueue = res.data;
                    await this.getUser();
                    this.$confirm(
                        {
                            title: 'Request Result',
                            message: 'Transfer success',
                            button: {
                                yes: 'OK',
                            }
                        }
                    )
                    this.$refs.toastx.toastbox('toast-msg-primary', res.txid, 3000)
                    await setTimeout(() => {
                        console.log('')
                    }, 5000)
                    window.location.reload()
                } else {
                    this.$refs.toastx.toastbox('toast-msg-danger', res.msg, 3000)
                }
                this.loading = false
            } catch (error) {
                this.loading = false
                this.$refs.toastx.toastbox('toast-msg-danger', 'Request failed!', 3000)
                console.log(error);
            }
        },
        // async doMigrate() {
        //     try {
        //         this.loading = true
        //         const response = await axios.post('/postexportqueue', {}, {
        //             headers: {
        //                 Authorization: 'Bearer ' + localStorage.getItem('token')
        //             }
        //         });
        //         let res = response.data;
        //         if (res.status == 'success') {
        //             this.minerqueue = res.data;
        //             await this.getUser();
        //             await this.getLastQueueNo();
        //             await this.getMinerQueue();
        //             this.$confirm(
        //                 {
        //                     title: 'Export Result',
        //                     message: res.message,
        //                     button: {
        //                         yes: 'OK',
        //                     }
        //                 }
        //             )
        //             this.$refs.toastx.toastbox('toast-msg-primary', res.status, 3000)
        //         } else {
        //             this.$refs.toastx.toastbox('toast-msg-danger', res.error, 3000)
        //         }
        //         this.loading = false
        //     } catch (error) {
        //         this.loading = false
        //         this.$refs.toastx.toastbox('toast-msg-danger', 'Migrate failed!', 3000)
        //         console.log(error);
        //     }
        // },
        // getminerqueue
        async getMinerQueue() {
            if (localStorage.getItem('token')) {
                // console.log('get user')
                try {
                    let response = await axios.get('getminerqueue', {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token')
                        }
                    });

                    let res = response.data;
                    // console.log('miner queue : ',res)
                    if (res.status == 'success') {
                        this.minerqueue = res.data;
                        // console.log('miner queue_1 : ', this.minerqueue)
                    } else {
                        this.$refs.toastx.toastbox('toast-msg-danger', 'No miner queue!', 3000)
                    }
                } catch (error) {
                    this.$refs.toastx.toastbox('toast-msg-danger', 'Invalid data!', 3000)
                    console.log(error);
                }
            } else {
                this.$router.push('/login')
            }
        },
        async getLastQueueNo() {
            if (localStorage.getItem('token')) {
                // console.log('get user')
                try {
                    let response = await axios.get('getlastqueueno', {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token')
                        }
                    });

                    let res = response.data;
                    // console.log('last queue : ', res)
                    if (res.status == 'success') {
                        this.lastqueue = res.data;
                    } else {
                        this.$refs.toastx.toastbox('toast-msg-danger', 'No last queue!', 3000)
                    }
                } catch (error) {
                    this.$refs.toastx.toastbox('toast-msg-danger', 'Invalid data!', 3000)
                    console.log(error);
                }
            } else {
                this.$router.push('/login')
            }
        },
        async getLastStaking() {
            // console.log('staking called')
            try {
                let response = await axios.get('laststaking', {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                });

                let res = response.data;
                // console.log(res)
                // if(res.success) {
                    this.staking = res.success;
                    // console.log(this.staking===undefined)
                    // console.log(this.staking)
                    if (!(this.staking === undefined) && (this.staking.status == 'waiting' || this.staking.status == 'running' || this.staking.status == 'exported')) {
                        this.claimavailable = parseFloat(this.staking.u_mined) - parseFloat(this.staking.u_withdraw);
                        if (this.claimavailable <= 0) this.claimavailable = 0;
                        this.wdamountreward = this.claimavailable;
                        // console.log('wdamountreward', this.wdamountreward)
                    } else if (!(this.staking === undefined) && this.staking.status == 'stoped') {
                        this.lockstaking = true;
                        this.totalstaking = parseFloat(this.staking.u_amount);
                        this.claimavailable = parseFloat(this.staking.u_mined) - parseFloat(this.staking.u_withdraw);
                        if (this.claimavailable <= 0) this.claimavailable = 0;
                    } else if (this.staking === undefined) {
                        this.claimavailable = 0;
                    } else {
                        this.claimavailable = 0;
                    }
                // } else {
                //     console.log(res)
                // }
                
                // console.log(res)
            } catch (error) {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    this.$router.push('/login')
                }
            }
        },     
        async getUser() {
            if(localStorage.getItem('token')) {
                // console.log('get user')
                try {
                    let response = await axios.get('user/' + process.env.VUE_APP_CODE, {
                        headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                        }
                    });

                    let res = response.data;
                    // console.log('user : ', res)
                    if(res.user) {
                        // if (res.user.exportstatus == 'exported') {
                        //     this.$router.push('/pinverification')
                        // }

                        this.user = res.user;
                        this.tnotif = res.totnotif;
                        this.samount = res.samount;
                        this.trxbalance = res.trxbalance;
                        if(this.trxbalance < 6) this.trxbalance = 0;
                        this.tokenbalance = res.tokenbalance;
                    } else {
                        localStorage.removeItem('token');
                        this.$router.push('/login')
                    }
                } catch (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('token');
                        this.$router.push('/login')
                    }
                }
            } else {
                this.$router.push('/login')
            }    
        },
        async doClaimReward(){

            this.errors = '';

            var a = document.getElementById('withdrawActionSheetReward');
            a.classList.remove("show")
            document.querySelector('body').classList.remove('modal-open');
            var elements = document.getElementsByClassName('modal-backdrop');
            elements[0].parentNode.removeChild(elements[0]);
            // console.log(this.stakingamount)
            var amount = parseFloat(this.wdamountreward);
            if(amount<=0) {
                this.$refs.toastx.toastbox('toast-msg-danger', 'Bad amount', 3000)
            } else {
                try {
                    const response = await axios.post('/postclaimreward', {
                        amount: amount,
                        pin: this.pinreward
                        // , otp: this.otpreward
                    }, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }});
                    let res = response.data;
                    if(res.status=='success') {
                        // this.getWalletTrx();
                        this.getLastStaking()
                        this.$refs.toastx.toastbox('toast-msg-primary','Claim waiting for confirmation!', 3000)
                        // return res.json({"result": "success", "txid": output});
                    } else {
                        this.$refs.toastx.toastbox('toast-msg-danger', res.error, 3000)
                    }
                } catch (error) {
                    this.getBalance();
                    this.$refs.toastx.toastbox('toast-msg-danger', 'Claim token failed!', 3000)
                    console.log(error);
                    // return res.json({"result": "failed", "txid": null, "status": error});
                }
            }

            
        },
        toDate(dt) {
            var date = new Date(dt);

            return date.getDate()+
                "/"+(date.getMonth()+1)+
                "/"+date.getFullYear()+
                " "+date.getHours()+
                ":"+date.getMinutes()+
                ":"+date.getSeconds();
        },
        fround(num) {
            var m = Number((this.$math.abs(num) * 10000000).toPrecision(15));
            return this.$math.round(m) / 10000000 * this.$math.sign(num);
        }
    },
}
</script>

<style scoped>
.text-responsive {
  font-size: calc(23% + 1vw + 1vh);
}
</style>