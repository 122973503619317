<template>
    <div>
        <div class="loader" v-if="loading"></div>
        <!-- App Header -->
        <div class="appHeader no-border transparent position-absolute">
            <div class="pageTitle"></div>
            <div class="right">
            </div>
        </div>
        <!-- * App Header -->

        <!-- App Capsule -->
        <div id="appCapsule" class="full-height">

            <!-- <div class="section mt-2 text-center">
            </div> -->
            <div class="section mb-5 p-2">
                <form @submit.prevent="submit">
                    <div class="card">
                        <div class="card-body pb-1 mb-3">
                            <div class="text-center">
                                <img src="/logo64.png" class="mb-3">
                                <h1>Log in</h1>
                                <h4>Fill the form to log in</h4>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="idminer">ID Miner</label>
                                    <input type="text" v-model="idminer" class="form-control" id="idminer" placeholder="Miner ID">
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>

                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="password">Password</label>
                                    <input type="password" v-model="password" class="form-control" id="password" autocomplete="off"
                                        placeholder="Your password">
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>
                            <vue-recaptcha v-if="sitekey!=null" :sitekey="sitekey" :loadRecaptchaScript="true" ref="recaptcha" type="invisible" @verify="onCaptchaVerified" @expired="onCaptchaExpired" />
                            <button type="submit" class="btn btn-primary btn-block btn-lg mt-3">Log in</button>
                        </div>
                    </div>


                    <!-- <div class="form-links mt-2">
                        <div>
                            <a href="#">Register Now</a>
                        </div>
                        <div><a href="#" class="text-muted">Forgot Password?</a></div>
                    </div> -->

                    <!-- <div class="form-button-group  transparent">
                    </div> -->
                    <!-- <vue-recaptcha sitekey="process.env.RECAPTCHA_SITE_KEY" :loadRecaptchaScript="true" ref="recaptcha" type="invisible"
                      @verify="onCaptchaVerified" @expired="onCaptchaExpired">
                    </vue-recaptcha> -->
                </form>

            </div>

        </div>

        <Toast ref="toastx" />
    </div>
</template>

<script>
import axios from 'axios';
import Toast from '../components/Toast'
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'Login',
  props: ['user'],
  components: {
      Toast,
    VueRecaptcha
  },
  data: () => ({
    idminer: '',
    password: null,
    showPass: false,
    recaptcha: '',
    loading: true,
    sitekey: null
  }),
  computed: {
    params() {
      return {
        idminer: this.idminer,
        password: this.password
      }
    }
  },
  async mounted() {
    this.sitekey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
      if(localStorage.getItem('token')) {
            this.$router.push('/home');
        } 
    this.loading = false;
  },
  methods: {
    onCaptchaVerified: function (recaptchaToken) {
      this.recaptcha = recaptchaToken
      this.validateCaptcha = true
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    // submitForm() {
    //   this.loading = true
    //   axios.post('/specialties', this.form)
    //     .then(res => {
    //       console.log(res)
    //       this.loading = false
    //       this.onCaptchaExpired()
    //     })
    //     .catch(err => {
    //       console.log(err)
    //       this.loading = false
    //     })
    // },
    async submit() {
        // console.log('submit')
        // this.login(this.params) // action to login
        this.loading = true
        const response = await axios.post('/login', {
          idminer: this.idminer,
          password: this.password,
          appcode: process.env.VUE_APP_CODE,
          'g-recaptcha-response': this.recaptcha
        });
        let res = response.data;
        // console.log(res)
        if(res.status=='success') {
          this.$refs.toastx.toastbox('toast-msg-primary','Login success!', 3000)
          localStorage.setItem('token', res.token);
          
          this.$emit('user-info-change', res.user)
          // redirect to PIN when not activated
          if(res.user.exportstatus=='exported') {
            this.$router.push('/pinverification');
          } else {
            this.$router.push('/exporttosmartcontract');
          }
        } else {
          this.$refs.toastx.toastbox('toast-msg-danger', res.message, 3000)
          this.$confirm(
            {
              title: 'Error Message',
              message: res.message,
              button: {
                yes: 'OK',
              }
            }
          )
        //   this.$refs.notif.SnackbarShow('error', res.message);
        }
        this.loading = false
    },
    clear() {
      // you can use this method to clear login form
      this.idminer = ''
      this.password = null
      this.$refs.observer.reset()
    }
  }
}
</script>

<style scoped>
#appCapsule {
    background-image: url('/appbg.jpeg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50% 50%;
}
</style>