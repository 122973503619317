<template>
    <div>

        <!-- App Header -->
        <div class="appHeader bg-primary text-light">
            <div class="left">
                <a @click="$router.go(-1)" class="headerButton goBack">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
                <!-- <router-link to="router.back()" class="headerButton goBack">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </router-link> -->
            </div>
            <div class="pageTitle">
                {{title.value}}
            </div>
            <div class="right">
                <a href="#" class="headerButton">
                    <ion-icon class="icon" name="refresh-outline" @click="reloadpage"></ion-icon>
                </a>
            </div>
        </div>
        <!-- * App Header -->
    </div>
</template>

<script>
// import axios from 'axios'

export default {
    name: 'Appheader',
    components: {
    },
    props: ['title'],
    data: function () {
      return {
      }
    },
    methods: {
      reloadpage() {
          console.log('clicked')
          this.$router.go()
      }
    },
}
</script>