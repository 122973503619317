<template>
    <div>
        <!-- toast bottom iconed -->
        <div id="toast-msg-primary" class="toast-box toast-bottom bg-primary">
            <div class="in">
                <ion-icon name="checkmark-outline"></ion-icon>
                <div class="text txtmsg">
                    {{cpmessage}}
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-text-light close-button">OK</button>
        </div>
        <div id="toast-msg-danger" class="toast-box toast-bottom bg-danger">
            <div class="in">
                <ion-icon name="close-circle-outline"></ion-icon>
                <div class="text txtmsg">
                    {{cpmessage}}
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-text-light close-button">OK</button>
        </div>
        <!-- * toast bottom iconed -->
    </div>
</template>

<script>
export default {
    name: 'Toast',
    components: {
    },
    data: function () {
      return {
          cpmessage: null
      }
    },
    created() {
        var toastCloseButton = document.querySelectorAll(".toast-box .close-button");
        var toastTaptoClose = document.querySelectorAll(".toast-box.tap-to-close");

        // close button toast
        toastCloseButton.forEach(function (el) {
            el.addEventListener("click", function (e) {
                e.preventDefault();
                this.closeToastBox();
            })
        })
        // tap to close toast
        toastTaptoClose.forEach(function (el) {
            el.addEventListener("click", function (e) {
                this.closeToastBox();
                console.log(e)
            })
        })

        // this.cpmessage = ''
    },
    methods: {
        closeToastBox: function() {
            var toastBoxes = document.querySelectorAll(".toast-box");
            toastBoxes.forEach(function (el) {
                el.classList.remove("show")
            })
        },
        toastbox(target, txt, time) {
            this.cpmessage = txt;
            // console.log(txt)
            // console.log(this.cpmessage)
            var a = document.getElementById(target);
            // var txtmsg = document.getElementsByClassName("txtmsg");
            // txtmsg.innerHTML = txt;
            this.closeToastBox()
            setTimeout(() => {
                a.classList.add("show")
            }, 100);
            if (time) {
                time = time + 100;
                setTimeout(() => {
                    this.closeToastBox()
                }, time);
            }
        }

    }
}
</script>